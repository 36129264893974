import React, { useEffect, useState } from "react";

import om from "../assets/om.png";
import moov from "../assets/moov.webp";
import espece from "../assets/especes.png";
import { useOmPayMutation, useOmTokenMutation } from "../redux/paymentService";
import { useGetRedevanceQuery, useUpdateRedevanceMutation } from "../redux/redevanceService";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";

const Payment = ({ selected, showDrop, setShowDrop }) => {
  
  const [pay] = useOmPayMutation();

  const [getToken] = useOmTokenMutation();
  const {data: redevance, isLoading, isFetching} = useGetRedevanceQuery(selected?.CustomerID);
  const [dataToUpdate, setDataToUpdate] = useState()
  const [payMultiple, setPayMultiple] = useState(false)
  const [multipleAmount, setMultipleAmount] = useState()
  

  const [token, setToken] = useState();

  let amount =selected?.Weeks.replace(/\s/, '')

  

  
  let data 

  

   
  
  const send = async () => {
    
    
    

    
    const paymentData = {
      montant : payMultiple && multipleAmount ? multipleAmount : selected.Weeks,
      date_paiement: new Date(),
      mode_paiement: 'OM',
      status: 'Payé',
      redevance: selected.CustomerID
    }

    const cancelData = {
      montant : selected.Weeks,
      date_paiement: new Date(),
      mode_paiement: 'OM',
      status: 'annulé',
      redevance: selected.CustomerID
    }
    

    localStorage.setItem('success_pay', JSON.stringify(paymentData))
    localStorage.setItem('redevance', JSON.stringify(redevance))
    localStorage.setItem('cancel_pay',JSON.stringify(cancelData))
    
    

    try {
      getToken().then((rep) => {
        if (rep.data) {
          setToken(rep.data.access_token);
          localStorage.setItem("access_token", rep.data.access_token);
          if (rep.data.access_token)
            pay({
              merchant_key: "2bbcdaeb",
              currency: "OUV",
              order_id: `redevance_no_${selected.CustomerID}_`+ Math.random().toFixed(4),
              amount: multipleAmount ? Number(multipleAmount) : Number(amount),
              return_url: "https://bumda.ntaverify.com/omredirect/validate",
              cancel_url: "https://bumda.ntaverify.com/omredirect/cancel",
              notif_url: "https://bumda.ntaverify.com/omredirect/notif",
              lang: "fr",
              reference: "Bumda_pays",
            }).then((rep) => {
              
            
              if(rep.data){ window.open(rep.data.payment_url)}
              
              
            });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };
 
  useEffect(() => {
    
  setDataToUpdate(redevance?.data)
  data = {...redevance?.data, attributes: {...redevance?.data.attributes, status: true}}
  
  setDataToUpdate(data)
  }, [redevance])

  if(payMultiple) {
    return(
      <div
      id="select-modal"
      tabindex="-1"
      aria-hidden="true"
      
      class={`${
        showDrop ? "block" : "hidden"
      } flex overflow-scroll overflow-x-hidden fixed left-1/3 top-10 z-50  w-full  h-[calc(100%-1rem)] max-h-full`}
    >
      <div class="relative p-4 w-full  max-w-md max-h-1/3 overflow-auto">
        <div class="relative bg-gray-300 rounded-lg shadow dark:bg-gray-700">
          <button onClick={() => setPayMultiple(!payMultiple)} className="w-full text-4xl flex items-center justify-center h-1/2 text-yellow-500 top-8 p-2 ">  {!payMultiple ? <FaToggleOff /> : <FaToggleOn/>} {!payMultiple? <p className="font-bold text-sm text-gray-700 ml-2">  Payer une seule écheance </p> : <p className="font-bold text-sm text-gray-700 ml-2">  Payer plusieurs écheances</p>}  </button>  
          <div class="flex items-center justify-between p-2 md:p-5 border-b rounded-t-xl dark:border-gray-600">
            <h3 class="text-lg flex flex-col items-center justify-center flex-wrap gap-2 font-semibold text-gray-900 dark:text-white">
              Saisir la somme à payer{" "}
              <input name='amout' id="amout" className="w-full h-5 rounded-2xl border-yellow-600  border-2" onChange={(e) => setMultipleAmount(e.target.value)} />
            </h3>

            <button
              onClick={() => setShowDrop(!showDrop)}
              type="button"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="select-modal"
            >
              <svg
                class="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <p className=" text-base pt-0 pb-0 p-4 ml-2 text-gray-500 font-semibold ">
            {" "}
            Pour le compte{" "}
            <span className="text-yellow-600 capitalize hover:shadow-md text-2xl">
              {" "}
              {selected?.ProjectName}{" "}
            </span>{" "}
          </p>
          <div class="p-4 md:p-5">
            <p class="text-gray-500 dark:text-gray-400 mb-4">
              Choisissez le mode de paiement
            </p>
            <ul class="space-y-4 mb-4">
              <li>
                <input
                  type="radio"
                  id="job-1"
                  name="job"
                  value="job-1"
                  class="hidden peer"
                  required
                />
                <label
                  for="job-1"
                  class="inline-flex items-center justify-between h-1/2 w-full p-2 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500"
                >
                  <div class="block">
                    <div class="w-full flex items-center justify-between text-lg font-semibold">
                      Orange Money{" "}
                      <img src={om} className="object-contain w-1/3 h-1/2" />{" "}
                    </div>
                    <div class="w-full text-gray-500 dark:text-gray-400">
                      Orange
                    </div>
                  </div>
                  <svg
                    class="w-4 h-4 ms-3 rtl:rotate-180 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                </label>
              </li>
              <li>
                <input
                  type="radio"
                  id="job-2"
                  name="job"
                  value="job-2"
                  class="hidden peer"
                />
                <label
                  for="job-2"
                  class="inline-flex items-center  justify-between h-1/2 w-full p-2 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500"
                >
                  <div class="block">
                    <div class="w-full flex items-center justify-between text-lg font-semibold">
                      Moov Money{" "}
                      <img src={moov} className="object-contain w-1/3 h-1/2" />{" "}
                    </div>

                    <div class="w-full text-gray-500 dark:text-gray-400">
                      Malitel
                    </div>
                  </div>
                  <svg
                    class="w-4 h-4 ms-3 rtl:rotate-180 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                </label>
              </li>
              <li>
                <input
                  type="radio"
                  id="job-3"
                  name="job"
                  value="job-3"
                  class="hidden peer"
                />
                <label
                  for="job-3"
                  class="inline-flex items-center justify-between h-1/2 w-full p-2 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500"
                >
                  <div class="block">
                    <div class="w-full flex items-center justify-between text-lg font-semibold">
                      Espèces{" "}
                      <img
                        src={espece}
                        className="object-contain w-1/3 h-1/2"
                      />{" "}
                    </div>

                    <div class="w-full text-gray-500 dark:text-gray-400">
                      Liquide
                    </div>
                  </div>
                  <svg
                    class="w-4 h-4 ms-3 rtl:rotate-180 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                </label>
              </li>
            </ul>
            <button
              onClick={() => send()}
              class="text-white inline-flex w-full justify-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Payer
            </button>
          </div>
        </div>
      </div>
    </div>
    )
  }
  
  return (
    <div
      id="select-modal"
      tabindex="-1"
      aria-hidden="true"
      
      class={`${
        showDrop ? "block" : "hidden"
      } flex overflow-y-auto overflow-x-hidden fixed left-1/3 top-12 z-50  w-full  h-[calc(100%-1rem)] max-h-full`}
    >

      
      
      <div class="relative p-2 w-full  max-w-md max-h-full">
        <div class="relative bg-gray-300 rounded-lg shadow dark:bg-gray-700">

          <button onClick={() => setPayMultiple(!payMultiple)} className="w-full text-4xl flex items-center justify-center text-yellow-500 top-8 p-2 ">  {!payMultiple ? <FaToggleOff /> : <FaToggleOn/>} {!payMultiple? <p className="font-bold text-sm text-gray-700 ml-2">  Payer une seule écheance </p> : <p className="font-bold text-sm text-gray-700 ml-2">  Payer plusieur écheances</p>}  </button>  
          
          <div class="flex items-center justify-between p-2 md:p-5 border-b rounded-t dark:border-gray-600">
            
            <h3 class="text-lg flex font-semibold text-gray-900 dark:text-white">
              Paiement de la somme de{" "}
              <p className="text-yellow-600 font-bold ml-2 text-2xl">
                {selected?.Weeks}
              </p>
            </h3>

            <button
              onClick={() => setShowDrop(!showDrop)}
              type="button"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="select-modal"
            >
              <svg
                class="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <p className=" text-base pt-0 pb-0 p-2 ml-2 text-gray-500 font-semibold ">
            {" "}
            Pour le compte{" "}
            <span className="text-yellow-600 capitalize hover:shadow-md text-2xl">
              {" "}
              {selected?.ProjectName}{" "}
            </span>{" "}
          </p>
          <div class="p-4 md:p-5">
            <p class="text-gray-500 dark:text-gray-400 mb-4">
              Choisissez le mode de paiement
            </p>
            <ul class="space-y-4 mb-4">
              <li>
                <input
                  type="radio"
                  id="job-1"
                  name="job"
                  value="job-1"
                  class="hidden peer"
                  required
                />
                <label
                  for="job-1"
                  class="inline-flex items-center justify-between w-full p-5 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500"
                >
                  <div class="block">
                    <div class="w-full flex items-center justify-between text-lg font-semibold">
                      Orange Money{" "}
                      <img src={om} className="object-contain w-1/3 h-1/3" />{" "}
                    </div>
                    <div class="w-full text-gray-500 dark:text-gray-400">
                      Orange
                    </div>
                  </div>
                  <svg
                    class="w-4 h-4 ms-3 rtl:rotate-180 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                </label>
              </li>
              <li>
                <input
                  type="radio"
                  id="job-2"
                  name="job"
                  value="job-2"
                  class="hidden peer"
                />
                <label
                  for="job-2"
                  class="inline-flex items-center justify-between w-full p-5 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500"
                >
                  <div class="block">
                    <div class="w-full flex items-center justify-between text-lg font-semibold">
                      Moov Money{" "}
                      <img src={moov} className="object-contain w-1/3 h-1/3" />{" "}
                    </div>

                    <div class="w-full text-gray-500 dark:text-gray-400">
                      Malitel
                    </div>
                  </div>
                  <svg
                    class="w-4 h-4 ms-3 rtl:rotate-180 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                </label>
              </li>
              <li>
                <input
                  type="radio"
                  id="job-3"
                  name="job"
                  value="job-3"
                  class="hidden peer"
                />
                <label
                  for="job-3"
                  class="inline-flex items-center justify-between w-full p-5 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500"
                >
                  <div class="block">
                    <div class="w-full flex items-center justify-between text-lg font-semibold">
                      Espèces{" "}
                      <img
                        src={espece}
                        className="object-contain w-1/3 h-1/3"
                      />{" "}
                    </div>

                    <div class="w-full text-gray-500 dark:text-gray-400">
                      Liquide
                    </div>
                  </div>
                  <svg
                    class="w-4 h-4 ms-3 rtl:rotate-180 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                </label>
              </li>
            </ul>
            <button
              onClick={() => send()}
              class="text-white inline-flex w-full justify-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Payer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
