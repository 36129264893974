import React, { useEffect } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Edit,
  Inject,
  ContextMenu,
  Search
} from "@syncfusion/ej2-react-grids";

import { ordersData, contextMenuItems, ordersGrid } from "../data/dummy";
import { Button, Header } from "../components";
import { useGetPaymentQuery } from "../redux/paymentService";
import product6 from "../data/product6.jpg";
import { useState } from "react";
import { BiLoaderCircle } from "react-icons/bi";
import { useStateContext } from "../contexts/ContextProvider";


const Orders = () => {
  const { data, isLoading, isFetching } = useGetPaymentQuery("");
  const [realData, setRealData] = useState();
  const { currentColor } = useStateContext();
  const [nbPayed, setNbPayed] = useState()

  const treatData = (filter) => {
    let result = [];
    let nb = 0
    data?.data.map((item) => {
      if (item.attributes.montant !== null)

        if (filter) {


          if (filter === 'today') {
            if (new Date(item.attributes.createdAt).getMonth() === new Date().getMonth()) {
              nb += 1
              result.push({
                OrderID: item.id,
                CustomerName: item.attributes.redevance.data?.attributes.redevable,

                TotalAmount: Number(item.attributes.montant?.replace(/\s/, "")),
                OrderItems: item.attributes.redevance.data?.attributes.type,
                Location: "USA",
                Status: item.attributes.status,
                StatusBg:
                  item.attributes.status === "En attente"
                    ? "#FB9678"
                    : item.attributes.status === "Payé"
                      ? "#8BE78B"
                      : "#FF5C8E",
                ProductImage: product6,
              });
            }

          } else if (filter === '3') {
            if (new Date(item.attributes.createdAt).getMonth() >= new Date().getMonth() - 3 && new Date(item.attributes.createdAt).getMonth() < new Date().getMonth() + 1) {
              nb += 1

              result.push({
                OrderID: item.id,
                CustomerName: item.attributes.redevance.data?.attributes.redevable,

                TotalAmount: Number(item.attributes.montant?.replace(/\s/, "")),
                OrderItems: item.attributes.redevance.data?.attributes.type,
                Location: "USA",
                Status: item.attributes.status,
                StatusBg:
                  item.attributes.status === "En attente"
                    ? "#FB9678"
                    : item.attributes.status === "Payé"
                      ? "#8BE78B"
                      : "#FF5C8E",
                ProductImage: product6,
              });
            }
          } else if (filter === '6') {

            if (new Date(item.attributes.createdAt).getMonth() >= new Date().getMonth() - 6 && new Date(item.attributes.createdAt).getMonth() < new Date().getMonth() + 1) {
              nb += 1

              result.push({
                OrderID: item.id,
                CustomerName: item.attributes.redevance.data?.attributes.redevable,

                TotalAmount: Number(item.attributes.montant?.replace(/\s/, "")),
                OrderItems: item.attributes.redevance.data?.attributes.type,
                Location: "USA",
                Status: item.attributes.status,
                StatusBg:
                  item.attributes.status === "En attente"
                    ? "#FB9678"
                    : item.attributes.status === "Payé"
                      ? "#8BE78B"
                      : "#FF5C8E",
                ProductImage: product6,
              });
            }


          } else if (filter === 'year') {

            let date = { m: new Date().getMonth(), y: new Date().getFullYear() }
            let lastYear = { m: new Date().getMonth(), d: new Date().getFullYear() }

            if (new Date(item.attributes.createdAt).getFullYear() >= new Date().getFullYear() - 1 || new Date(item.attributes.createdAt).getFullYear() <= new Date().getFullYear()) {
              nb += 1

              result.push({
                OrderID: item.id,
                CustomerName: item.attributes.redevance.data?.attributes.redevable,

                TotalAmount: Number(item.attributes.montant?.replace(/\s/, "")),
                OrderItems: item.attributes.redevance.data?.attributes.type,
                Location: "USA",
                Status: item.attributes.status,
                StatusBg:
                  item.attributes.status === "En attente"
                    ? "#FB9678"
                    : item.attributes.status === "Payé"
                      ? "#8BE78B"
                      : "#FF5C8E",
                ProductImage: product6,
              });
            }


          }

        } else {
          nb += 1

          result.push({
            OrderID: item.id,
            CustomerName: item.attributes.redevance.data?.attributes.redevable,

            TotalAmount: Number(item.attributes.montant?.replace(/\s/, "")),
            OrderItems: item.attributes.redevance.data?.attributes.type,
            Location: "USA",
            Status: item.attributes.status,
            StatusBg:
              item.attributes.status === "En attente"
                ? "#FB9678"
                : item.attributes.status === "Payé"
                  ? "#8BE78B"
                  : "#FF5C8E",
            ProductImage: product6,
          });
        }

    });

    setRealData(result.reverse());
    setNbPayed(nb)
  };

  let grid;

  const toolbarClick = (args) => {
    if (grid && args.item.id === 'Grid_excelexport') {
      const excelExportProperties = {
        fileName: 'paiements' + Math.floor(Math.random() * (999 - 100 + 1) + 100) + '.xlsx'
      };
      grid.showSpinner();
      grid.excelExport(excelExportProperties);
    }
  }
  const excelExportComplete = () => {
    grid?.hideSpinner();
  }


  useEffect(() => {
    treatData();
  }, [data]);

  console.log("paiement data", data);
  return (
    <div className="m-2 md:m-10 mt-20 md: p-10 bg-white rounded-3xl">
      <Header category="Page" title="Recouvremennts" />
      {/* <div className="flex justify-center items-center m-2 p-2">
        <select className="w-56 border-b-slate-800 border-b-2 border-l-2 rounded-l-xl border-l-slate-800">
          <option value="Bamako">Bamako</option>
          <option value="Commune I">Commune I</option>
          <option value="Commune II">Commune II</option>
          <option value="Commune III">Commune III</option>
          <option value="Commune VI">Commune VI</option>
          <option value="Commune V">Commune V</option>
          <option value="Commune VI">Commune VI</option>
        </select>
      </div> */}
      <div className="flex  items-center justify-end lg: mr-36 flex-wrap gap-5 p-2 ">
        <span onClick={() => treatData('today')} style={{ backgroundColor: currentColor }} className={`shadow-sm shadow-black p-2 rounded-xl cursor-pointer  dark:bg-secondary-dark-bg  text-gray-200`}>Mois </span>
        <span onClick={() => treatData('3')} style={{ backgroundColor: currentColor }} className={`shadow-sm shadow-black p-2 rounded-xl cursor-pointer  dark:bg-secondary-dark-bg  text-gray-200`}>3 Mois </span>
        <span onClick={() => treatData('6')} style={{ backgroundColor: currentColor }} className={`shadow-sm shadow-black p-2 rounded-xl cursor-pointer  dark:bg-secondary-dark-bg  text-gray-200`}>6 Mois </span>
        <span onClick={() => treatData('year')} style={{ backgroundColor: currentColor }} className={`shadow-sm shadow-black p-2 rounded-xl cursor-pointer  dark:bg-secondary-dark-bg  text-gray-200`}>Année </span>
      </div>

      <div className="flex flex-wrap lg:flex-nowrap justify-center">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
          <div className="flex justify-between items-center">
            <div>
              <p className="font-bold text-gray-400">NB Usagers</p>
              <p className="text-xl text-center font-semibold">{nbPayed} </p>
            </div>
          </div>
          <div className="mt-6">
            <Button
              color="white"
              bgColor={currentColor}
              text="Télécharger"
              borderRadius="10px"
              size="md"
              click={() => grid.excelExport({ fileName: 'paiements' + Math.floor(Math.random() * (999 - 100 + 1) + 100) + '.xlsx' })}

            />
          </div>

        </div>
      </div>

      {isLoading || isFetching || !realData ? (
        <div className="flex justify-center items-center text-9xl">
          <BiLoaderCircle />
        </div>

      ) : (
        <GridComponent
          id="Grid"
          dataSource={realData}
          allowPaging
          allowSorting
          allowFiltering
          toolbar={['Search', 'ExcelExport']}
          ref={(g) => (grid = g)}
          excelExportComplete={excelExportComplete}
          allowExcelExport={true}
          toolbarClick={toolbarClick}
        >
          <ColumnsDirective>
            {ordersGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject
            services={[
              Resize,
              Sort,
              ContextMenu,
              Filter,
              Page,
              ExcelExport,
              Edit,
              PdfExport,
              Search
            ]}
          />
        </GridComponent>
      )}
    </div>
  );
};

export default Orders;
