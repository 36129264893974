import React from 'react'

const Footer = () => {
  return (
    <div>
      <footer class="relative bg-blueGray-200 pt-8 pb-6 mt-8">
            <div class="container mx-auto px-4">
              <div class="flex flex-wrap items-center md:justify-between justify-center">
                <div class="w-full md:w-6/12 px-4 mx-auto text-center">
                  <div class="text-sm text-blueGray-500 font-semibold py-1">
                   BUMDA
                    by{" "}
                    <a
                      href="https://nta-technologie.com/"
                      class="text-blueGray-500 hover:text-blueGray-800"
                      target="_blank"
                    >
                      {" "}
                      NTA
                    </a>
                    .
                  </div>
                </div>
              </div>
            </div>
          </footer>
    </div>
  )
}

export default Footer