import React, { useEffect, useState } from "react";

import om from "../assets/om.png";
import { IoMdCodeWorking } from "react-icons/io";
import { MdSchool, MdStar } from "react-icons/md";
import { GoAlert } from "react-icons/go";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { useGetRedevableQuery } from "../redux/redevableService";
import { useParams } from "react-router-dom";
import {
  useGetRedevanceByRedevableIdQuery,
  useGetRedevanceQuery,
} from "../redux/redevanceService";
import { TiWarningOutline } from "react-icons/ti";

const Details = () => {
  const { id } = useParams();
  const { data, isFetching, isSucces, isError } = useGetRedevableQuery(id);
  const { data: redevanceData, isFetching: isRedevanceFetching } =
    useGetRedevanceByRedevableIdQuery(id);

  const [redevable, setRedevable] = useState();

  console.log(redevanceData);

  const nextPayment = redevanceData?.data?.filter(
    (item) => item.attributes.status === false
  );
  const payed = redevanceData?.data.filter(
    (item) => item.attributes.status === true
  );

  useEffect(() => {
    setRedevable(data);
  }, [data]);
  return (
    <div className="flex flex-col md:flex-row min-w[360px]:flex-row mt-5 w-full bg-white">
     
      <div className="flex flex-col  lg: md:w-9/12 min-w[360px]:w-full min-w min-w[360px]:flex-row  h-full bg-gray-100 ">
        <div className="m-2 p-2 md:ml-28 min-w[360px]:ml-2 flex flex-col min-w[360px]:flex-row ">
          <h1 className="font-bold text-black ">Usager</h1>

          <div className="flex  justify-between items-center mt-3 min-w[360px]:flex-wrap min-w[360px]:gap-5 text-gray-700 min-w[360px]:flex-row">
            <h2 className="font-semibold text-xl mr-3">
              {" "}
              {redevable && redevable?.data.attributes.name}{" "}
            </h2>
            <p >
              Contrat signé le{" "}
              {redevable &&
                new Date(redevable?.data.attributes.date).toLocaleDateString(
                  "fr"
                )}
            </p>{" "}
            <p className="font-bold flex flex-wrap gap-2 justify-center items-center">
              <GoAlert className="text-orange-600 text-2xl" />
              {" "}
              Prochaine Echéance{" "}
              <span className="text-orange-600 ">
                {" "}
                {redevanceData &&
                  new Date(
                    nextPayment[0]?.attributes?.date
                  ).toLocaleDateString()}{" "}
              </span>{" "}

              
            </p>
          </div>
        </div>
        <div className="flex flex-col m-2 p-2 md:ml-28 min-w[360px]:ml-2">
          <h2 className="font-semibold mb-4">Type d'usager</h2>
          <p className="text-gray-700">
            {redevable && redevable?.data.attributes.category}
          </p>
          <p className="text-gray-700"> Nombre de place : 150 </p>
          <p className="text-gray-700">
            {" "}
            Bureau : {redevable && redevable?.data.attributes.office}{" "}
          </p>
          <p className=" text-gray-700">
            {" "}
            {`${redevable && redevable?.data.attributes.pays}, ${
              redevable && redevable?.data.attributes.region
            }, ${redevable && redevable?.data.attributes.ville}, ${
              redevable && redevable?.data.attributes.adresse_details
            }`}{" "}
          </p>
        </div>

        <div className=" ">
          <h1 className="p-2 m-2 font-bold md:ml-28 min-w[360px]:ml-2 uppercase underline">
            Echéance non payé
          </h1>
          <div className="flex flex-wrap  gap-6 justify-between m-2 p-2 md:ml-28 min-w[360px]:ml-">
            <span> Status </span>
            <div className="flex flex-wrap gap-9 mr-16">
              <span> Date </span>
              <span> Montant </span>
            </div>
          </div>

          <hr className="text-black h-1 bg-gray-600" />
          {nextPayment?.length > 0 ? (
            nextPayment?.map((item) => (
              <div className="flex justify-between  m-2 p-2 md:ml-28 min-w[360px]:ml- text-base font-body capitalize">
                <div>
                  <p>{redevable && !redevable?.data.attributes.status && <span className="bg-red-200 p-2 m-2 rounded-lg text-red-600 ">
              Non Payé
            </span>}</p>
                </div>

                <div className="flex flex-wrap gap-10 mr-6">
                  <p>{new Date(item.attributes.date).toLocaleDateString('fr')}</p>
                  <p>{Number((item.attributes.montant).replace(/[^0-9]/g,""))} Fcfa</p>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center font-body flex items-center justify-center flex-wrap gap-3">
              {" "}
              <TiWarningOutline className="text-yellow-500 text-5xl" /> Aucune
              rédevance en attente de paiement{" "}
            </p>
          )}
          {/* <div className="flex justify-between  m-2 p-2 md:ml-28 min-w[360px]:ml- text-base font-body capitalize">
              <div>
                <p>papa</p>
              </div>

              <div className="flex flex-wrap gap-10 mr-6">
                <p>20 / 03 / 2024</p>
                <p>20 000 Fcfa</p>
              </div>
            </div> */}
        </div>
      </div>
      <div className="flex flex-col  lg:w-1/3 md:w-1/3 h-[100vh]">
          <div className="flex flex-col bg-slate-100  h-1/2 ">
{        payed?.length > 0 ? 

<>
          <h2 className="m-2 p-2 font-semibold"> Montant </h2>

          <div className="flex justify-between ">
            <h2 className="m-2 p-2 font-semibold">
              {" "}
              {payed &&
                payed[payed.length - 1]?.attributes?.montant + " Fcfa"}{" "}
            </h2>{" "}
            <span className="bg-green-200 p-2 m-2 rounded-lg text-green-600 ">
              Payé
            </span>
          </div>

          <div className="m-2 p-2 flex flex-col flex-wrap gap-5 text-gray-500 text-base">
            <div className="flex justify-between items-center">
              {" "}
              <p>Moyen de paiement</p>{" "}
              <img className="object-contain w-24" src={om} alt="" />{" "}
            </div>
            <div className="flex justify-between items-center">
              {" "}
              <p>Date de paiement </p>{" "}
              <p className="font-bold text-black">
                {" "}
                {payed &&
                  new Date(
                    payed[payed.length - 1]?.attributes?.updatedAt
                  ).toLocaleDateString()}{" "}
              </p>{" "}
            </div>
          </div>

          <button className="mt-20"> Télécharger reçu </button></>
         :  <p className="font-bold flex flex-wrap gap-1 justify-center items-center h-1/2">
              <GoAlert className="text-orange-600 text-2xl" />
              {" "}
             
              <span className="text-orange-600 ">
                  <p> Aucun paiement n'as été effectué pour l'instant... </p>
              </span>{" "} </p>  
        }
        </div> 
    
       
        <div className="bg-slate-100 ">
          <h2 className="ml-40 font-bold uppercase underline "> Activités </h2>
        </div>
        
        <div className="flex   bg-slate-200 h-1/2 ">
          <div className="m-2 p-2  w-full scroll-smooth overflow-y-auto">
            <VerticalTimeline>
              {payed &&
                payed.map((item, index, array) => (
                  <div className="w-full">
                    <VerticalTimelineElement
                      className="vertical-timeline-element--work flex font-body text-sm"
                      contentStyle={{
                        background: "rgb(33, 150, 243)",
                        color: "#fff",
                        marginLeft: "-20px",
                      }}
                      contentArrowStyle={{
                        borderRight: "7px solid  rgb(33, 150, 243)",
                      }}
                      dateClassName={"text-xs text-black mx-10 "}
                      date={`${new Date(
                        item.attributes.updatedAt
                      ).toLocaleDateString("Fr")} `}
                      iconStyle={{
                        background: "rgb(33, 150, 243)",
                        color: "#fff",
                      }}
                      icon={<IoMdCodeWorking />}
                    >
                      <div className="flex justify-start items-center flex-wrap gap-2  ">
                        <h3 className="vertical-timeline-element-title">
                          {item.attributes.type}
                           <span className="bg-green-200 p-2 m-2 rounded-lg text-green-600 ">
                          Payé
                        </span>
                        </h3>
                       
                        <h4 className="vertical-timeline-element-subtitle">
                        {` Montant : ${item.attributes.montant} Fcfa`}
                      </h4>
                      </div>

                    
                      <div className="flex flex-wrap gap-2 justify-center items-center"></div>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                      className="vertical-timeline-element--work text-sm"
                      dateClassName={"text-xs text-black mx-10 "}
                      date={`${new Date(
                        array[index +1]?.attributes.updatedAt
                      ).toLocaleDateString("Fr")} `}
                      iconStyle={{
                        background: "rgb(33, 150, 243)",
                        color: "#fff",
                      }}
                      icon={<IoMdCodeWorking />}
                      contentStyle={{
                        marginRight: "-20px",
                        
                      }}
                    >
                      <div className=" flex  justify-start items-center flex-wrap gap-2">
                        <h3 className="vertical-timeline-element-title ">
                          { array[index +1]?.attributes.type}
                          <span className="bg-green-200 p-2 m-2 rounded-lg text-green-600 ">
                            Payé
                          </span>
                        </h3>
                        <h4 className="vertical-timeline-element-subtitle">
                          {`Montant : ${ array[index +1]?.attributes.montant} Fcfa`}
                        </h4>
                      </div>
                    </VerticalTimelineElement>
                  </div>
                ))}

              <VerticalTimelineElement
                iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
                icon={<MdStar />}
              />
            </VerticalTimeline>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
