import React, { useEffect, useState } from "react";
import { FaRankingStar } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAuteurQuery } from "../redux/auteursService";
import { BiLoaderCircle } from "react-icons/bi";
import { RiLoader2Line } from "react-icons/ri";

const DetailsAuteur = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isFetching, isLoading, isSuccess, isError } =
    useGetAuteurQuery(id);
  const apiUrl = process.env.REACT_APP_API_TEST_URL;

  return (
    <div class="p-16">
      <div class="p-8 bg-white shadow mt-24">
        {console.log("auteur", data)}{" "}
        <div class="grid grid-cols-1 md:grid-cols-3">
          {" "}
          <div class="grid grid-cols-3 text-center order-last md:order-first mt-20 md:mt-0">
            {" "}
            <div>
              {" "}
              <p class="font-bold text-gray-700 text-xl">22</p>{" "}
              <p class="text-gray-400">Oeuvres</p>{" "}
            </div>{" "}
            <div>
              {" "}
              <p class="font-bold text-gray-700 text-xl">10</p>{" "}
              <p class="text-gray-400">Oeuvres actif</p>{" "}
            </div>{" "}
            <div>
              {" "}
              <p class="font-bold text-gray-700 text-xl">89</p>{" "}
              <p class="text-gray-400">N. R. perçu</p>{" "}
            </div>{" "}
          </div>{" "}
          <div class="relative">
            {" "}
            <div class="w-48 h-48 bg-indigo-100 mx-auto rounded-full shadow-2xl absolute inset-x-0 top-0 -mt-24 flex items-center justify-center text-indigo-500">
              {isFetching || isLoading ? (
                <div className="flex animate-spin">
                  {" "}
                  <BiLoaderCircle />{" "}
                </div>
              ) : data?.data.attributes.photo.data ? (
                <img
                  src={`${apiUrl}${data?.data.attributes.photo?.data.attributes.formats.thumbnail?.url}`}
                  className="flex w-9/12 rounded-full h-auto object-contain mix-blend-multiply"
                />
              ) : <RiLoader2Line className="animate-spin" />}
            </div>{" "}
          </div>{" "}
          <div class="space-x-8 flex justify-between mt-32 md:mt-0 md:justify-center">
            {/* <button class="text-white py-2 px-4 uppercase rounded bg-blue-400 hover:bg-blue-500 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5">
              {" "}
              Connect
            </button>{" "} */}
            <button
              onClick={() => alert("Fonctionnalité bientôt disponible")}
              class="text-white py-2 px-4 uppercase rounded bg-gray-700 hover:bg-gray-800 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5"
            >
              {" "}
              Message
            </button>{" "}
            <button
              onClick={() => navigate("/membercard/" + data?.data.id)}
              class="text-white py-2 px-4 uppercase rounded bg-gray-700 hover:bg-gray-800 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5"
            >
              {" "}
              Carte de membre
            </button>{" "}
          </div>{" "}
        </div>{" "}
        <div class="mt-20 text-center border-b pb-12">
          {" "}
          <h1 class="text-4xl flex flex-wrap gap-5 justify-center items-center font-medium text-gray-700">
            {isFetching || isLoading ? (
              <div className="flex animate-spin">
                {" "}
                <BiLoaderCircle />{" "}
              </div>
            ) : (
              `${data?.data && data.data.attributes.nom} ${data?.data && data.data.attributes.prenom
              }`
            )}
            ,{" "}
            <span class="flex flex-wrap gap-3 font-light text-gray-500">
              {" "}
              <FaRankingStar className="text-black" /> 6
            </span>
          </h1>{" "}
          <p class="font-light text-gray-600 mt-3">Catégorie : Musique</p>{" "}
          <p class="mt-8 text-gray-500">Description: Artiste Traditionnel</p>{" "}
          <p class="mt-2 text-gray-500">
            Adresse:{" "}
            {isFetching || isLoading ? (
              <div className="flex animate-spin">
                {" "}
                <BiLoaderCircle />{" "}
              </div>
            ) : (
              ` ${data?.data && data.data.attributes.pays},  ${data?.data && data.data.attributes.region
              },  ${data?.data && data.data.attributes.ville}, ${data?.data && data.data.attributes.adresse
              } `
            )}
          </p>{" "}
        </div>{" "}
        <div class="mt-12 flex flex-col justify-center">
          {" "}
          <p class="text-gray-600 text-center font-light lg:px-16">
            {isFetching || isLoading ? (
              <div className="flex animate-spin">
                {" "}
                <BiLoaderCircle />{" "}
              </div>
            ) : (
              `Né le: ${data?.data && data.data.attributes.date_naissance}`
            )}
          </p>{" "}
          {/* <button class="text-indigo-500 py-2 px-4  font-medium mt-4">
            {" "}
            Show more
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default DetailsAuteur;
