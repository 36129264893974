import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useParams, useLocation, useNavigate, useNavigation } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import { Navbar, Footer, Sidebar, ThemeSettings, ProtectedRoute } from "./components";
import {
  Ecommerce,
  Orders,
  Calendar,
  Employees,
  Stacked,
  Pyramid,
  Customers,
  Kanban,
  Area,
  Bar,
  Pie,
  Financial,
  ColorPicker,
  ColorMapping,
  Editor,
  Line,
  Auth,
  Auteurs,
  Redevances,
  Profile,
  Details,
  DetailAuteur,
  OmRedirect,
  MemberID,
  DetailsProg,
  Users
} from "./pages";

import "./App.css";
import { useStateContext } from "./contexts/ContextProvider";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode } = useStateContext();
  const StateToken  = useSelector((state) => state?.auth.data?.jwt)
  const localeToken = localStorage.getItem("auth") &&  localStorage.getItem("auth")!== "undefined"? JSON.parse(localStorage.getItem("auth")) : null
  

  
  const [isAuthenticated, setIsAuthenticated] = useState()

  const token = StateToken ? StateToken : localeToken

  useEffect(() => {
  setIsAuthenticated(token ? true : false)
  // localStorage.removeItem('success_pay')
  // localStorage.removeItem('cancel_pay')
  
  },[])
  if(!window.navigator.onLine) toast.error('Vous êtes hors ligne')

  useEffect(() => {
    setIsAuthenticated(token ? true : false)
    
    },[token])
 
  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <BrowserRouter>
      
        <div className="flex relative dark:bg-main-dark-bg">
          <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
            <TooltipComponent content="Settings" position="Top">
              <button
                type="button"
                className="text-3xl p-3 hover:drop-shadow-xl 
              hover:bg-light-gray text-white"
              onClick={() => setThemeSettings(true)}
                style={{ background: currentColor, borderRadius: "50%" }}
              >
                <FiSettings />
              </button>
            </TooltipComponent>
          </div>
         

          
            {isAuthenticated && activeMenu &&
            <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
             <Sidebar />
            </div>
             } 
          
          

          <div
            className={`dark:bg-main-dark-bg bg-main-bg min-h-screen  
          w-full ${ isAuthenticated && activeMenu 
                    ? "md:ml-72" : "flex-2"}`}
          >
            {isAuthenticated &&
              <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
              <Navbar isAuthenticated={isAuthenticated} />
            </div>
            }
            <ToastContainer />

            <div>
             {themeSettings && <ThemeSettings />}
              <Routes>
                {/* Dashboard */}
                <Route path="/" element={ isAuthenticated? <Ecommerce /> : <Auth />} />
                <Route element={<ProtectedRoute />} >
                <Route path="/ecommerce" element={<Ecommerce />} />
                <Route path="/auth" element={<Auth />} />


                {/* Pages */}
                <Route path="/orders" element={<Orders />} />
                <Route path="/auteurs" element={<Auteurs />} />
                <Route path="/employees" element={<Employees />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/redevances" element={<Redevances />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/details/:id" element={<Details />} />
                <Route path="/auteur/:id" element={<DetailAuteur />} />
                <Route path="/omredirect/:id" element={<OmRedirect />} />
                <Route path="/membercard/:id" element={<MemberID />} />
                <Route path="/prog/:id" element={<DetailsProg />} />
                <Route path="/users" element={<Users />} />

                {/* Apps */}
                <Route path="/kanban" element={<Kanban />} />
                <Route path="/editor" element={<Editor />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/color-picker" element={<ColorPicker />} />

                {/* Charts */}
                <Route path="/line" element={<Line />} />
                <Route path="/area" element={<Area />} />
                <Route path="/bar" element={<Bar />} />
                <Route path="/pie" element={<Pie />} />
                <Route path="/financial" element={<Financial />} />
                <Route path="/color-mapping" element={<ColorMapping />} />
                <Route path="/pyramid" element={<Pyramid />} />
                <Route path="/stacked" element={<Stacked />} />

                </Route>
              </Routes>
            </div>
          </div>
        </div>
        <Footer />
        
      </BrowserRouter>

     
    </div>
  );
};

export default App;
