import React from "react";
import card from "../assets/a.png";
import artist from "../assets/artiste.jpg";
import logo from "../assets/logo.png";
import moment from "moment";
import QRCode from "react-qr-code";

const MemberCard = ({ member }) => {
  const date = new Date();
  const addMonth = 5;
  const apiUrl = process.env.REACT_APP_API_TEST_URL;


  return (
    <div className="flex justify-center items-center flex-col mt-10 p-2">
      {/* <img className="w-780" alt="card" src={card} /> */}

      <div className="w-780 h-[460px] rounded-3xl flex flex-col p-2 bg-gray-700 mt-10">
        <div className="bg-yellow-500 flex   flex-wrap gap-5 rounded-3xl rounded-b-none w-full h-[40%]">
          <div className="flex flex-col w-[100%]">
            <div className=" items-center  h-1/5 justify-center flex">
              <img src={logo} className="w-96 mt-20 ml-5 object-contain" />
            </div>

            <div className="relative ml-10  bg-white w-44 h-44 rounded-3xl">
              <img
                src={`${apiUrl}${member?.attributes?.photo?.data?.attributes?.url}`}
                alt="artiste"
                className="p-3 w-full mix-blend-multiply h-full object-fill "
              />
            </div>
          </div>
        </div>

        <div className="w-full bg-white rounded-3xl rounded-t-none">
          <div className="relative ml-64 w-1/2  ">
            <h1 className="font-bold text-3xl text-blue-600">{`${member?.attributes.nom} ${member?.attributes.prenom}`}</h1>
            <h3 className="font-semibold text-xl text-yellow-400">
              {" "}
              Artiste Chanteur{" "}
            </h3>
          </div>

          <div className="mt-5 m-2 p-5 flex justify-around items-center">
            <div className="text-blue-600 flex flex-col flex-wrap gap-3">
              <p>
                Carte no:{" "}
                {`BUMDA_${member?.id}_PEI_${(Math.random().toFixed(4)) * 100000}`}{" "}
              </p>

              <p>
                {" "}
                {`Mali ${member?.attributes.region} ${member?.attributes.ville}`}
              </p>
            </div>
            <div className=" h-20 border-l-4 border-dotted border-gray-600"></div>
            <div className="text-blue-600 flex flex-col flex-wrap gap-3">
              <div>
                <p> Fait le</p>
                <p> {new Date().toLocaleDateString()} </p>
              </div>
              <div>
                <p> expire le</p>
                <p>
                  {" "}
                  {moment(date)
                    .add(addMonth, "years")
                    .toDate()
                    .toLocaleDateString()}{" "}
                </p>
              </div>
            </div>
            <div className=" h-20 border-l-4 border-dotted border-gray-600"></div>

            <div className="text-blue-600 flex flex-col flex-wrap gap-3">
              <p> {`${member?.attributes.email}`} </p>
              <p> Tel: {`${member?.attributes.phone}`}</p>
            </div>

            <div className=" flex flex-col flex-wrap gap-1  items-center justify-center text-center">
              <div className="relative w-20 h-20 bottom-2 bg-white">
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={"value"}
                  viewBox={`0 0 256 256`}
                />
              </div>
              <p> Signature </p>
              <p> www.bumda.ml </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberCard;
