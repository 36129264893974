import React, { useEffect, useState } from 'react'
import { useGetUsersQuery, useUpdateUserMutation } from '../redux/usersService'
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Grid,
  Toolbar,
  Sort,
  Filter,
  ExcelExport,
  DetailRow,
  ToolbarItems,
  Search
} from "@syncfusion/ej2-react-grids";

import { redevancesData, redevancessGrid } from "../data/dummy";
import { AddProgOeuvre, Button, Header, Payment, Register } from "../components";

import {
  BsKanban,
  BsBarChart,
  BsBoxSeam,
  BsCurrencyDollar,
  BsShield,
  BsChatLeft,
  BsPeople,
} from "react-icons/bs";

import { useStateContext } from "../contexts/ContextProvider";

import { Tooltip } from "react-tooltip";
import { NavLink } from "react-router-dom";
import { RiLoader2Line, RiLoader3Line } from "react-icons/ri";
import cfa from "../assets/fcfa.png";

import { IoIosPersonAdd } from 'react-icons/io';
import { CiEdit } from "react-icons/ci";





const Users = () => {

const {data, isLoading, isFetching, isSuccess, refetch} = useGetUsersQuery("")
 const [showDrop, setShowDrop] = useState(false);
  const [realData, setRealData] = useState();
  const [nbAgent, setNbAgent] = useState();
  const [nbAdmin, setNbAdmin] = useState();
  const [nbTotal, setNbTotal] = useState();
  const [toCollect, setToCollect] = useState();
  const { currentColor } = useStateContext();

  const [selectedUser, setSelectedUser] = useState();
  const [toPay, setToPay] = useState();
  const [showForm, setShowForm] = useState(false)
  const [showUpdateUser, setShowUpdateUser] = useState(false)
  const [role, setRole] = useState(3)

  const [updateUser] = useUpdateUserMutation()
  const gridFcfa = (props) => (
    <div  onClick={() => setShowUpdateUser(!showUpdateUser) } className="flex items-center justify-center cursor-pointer">
      {props.Weeks} 
      <CiEdit />
    </div>
  );

  const linkTemplate = (props) => (
    <NavLink to={"/prog/" + props.CustomerID}>
      <a href="">{props.ProjectName}</a>
    </NavLink>
  );

 
  const headers = [
    {
      icon: <BsPeople />,
      amount: !nbAgent ? <RiLoader3Line /> : nbAgent,
      // percentage: '-4%',
      title: "Agents",
      iconColor: "#03C9D7",
      iconBg: "#E5FAFB",
      pcColor: "red-600",
    },
    {
      icon: <BsPeople />,
      amount: !nbAdmin ? <RiLoader3Line /> : nbAdmin,
      // percentage: "+23%",
      title: "Administrateur",
      iconColor: "rgb(255, 244, 229)",
      iconBg: "rgb(254, 201, 15)",
      pcColor: "green-600",
    },
  
  ];
    
  let grid;
    
    const toolbarClick = (args) => {
        if (grid && args.item.id === 'Grid_excelexport') {
          const excelExportProperties = {
                fileName:  'Utilisateurs' +  Math.floor(Math.random()*(999-100+1)+100) + '.xlsx'
            };
            grid.showSpinner();
            grid.excelExport(excelExportProperties);
        }
    }
    const excelExportComplete = () => {
        grid?.hideSpinner();
    }

  // les Images du tableau
  const customerGridImage = (props) => (
    <div className="image flex gap-4">
      <img
        className="rounded-full w-10 h-10"
        src={props.EmployeeImage}
        alt="employee"
      />
      <div>
        <p>{props.CustomerName}</p>
        <p>{props.email}</p>
      </div>
    </div>
  );

  const customerGridStatus = (props) => (
    <div className="flex gap-2 justify-center items-center text-gray-700 capitalize">
      <p
        style={{ background: props.StatusBg }}
        className="rounded-full h-3 w-3"
      />
      <p>{props.Status}</p>
    </div>
  );

  // Les entetes du tableau
  const usersGrid = [
    { type: "checkbox", width: "50" },
    {
      field: "ProjectName",
      headerText: "Nom",
      template: linkTemplate,
      width: "150",
      textAlign: "Center",
    },
    {
      headerText: "email",
      width: "200px",
      template: customerGridImage,
      textAlign: "Center",
    },

    {
      field: "Status",
      headerText: "Status",
      width: "130",
      format: "yMd",
      textAlign: "Center",
      template: customerGridStatus,
    },
    {
      field: "Weeks",
      headerText: "Role",
      width: "150",
      template: gridFcfa,
      textAlign: "Center",
    },
    // {
    //   field: "date",
    //   headerText: "Date de création",
    //   width: "200",
    //   format: "yMd",
    //   textAlign: "Center",
    // },

    {
      field: "Location",
      headerText: "Bureau",
      width: "150",
      textAlign: "Center",
    },

    {
      field: "CustomerID",
      headerText: "ID",
      width: "120",
      textAlign: "Center",
      isPrimaryKey: true,
    },

    // {
    //   field: "Action",
    //   headerText: "Action",
    //   width: "120",
    //   textAlign: "Center",
    //   isPrimaryKey: true,
    //   template: actionButton,
    // },
  ];


  //Traitement des données du tableau
  const treatData = () => {
    let result = [];
    let toCollectAmout = 0;
    let list = [];
    let nbAgent = 0;
    let nbAdmin = 0;
    let nbTotal = 0;
   
    data?.map((item) => {
      if (item.username !== null) {
        result.push({
          CustomerID: item.id,
          ProjectName: item.username,
          email: item.email,
          date: new Date(item.createdAt).toLocaleString("fr"),
          Status: item.confirmed ? "Validé" : "Bloqué",
          Weeks: item?.role?.name,
          Location: 'Mali',
          ReportsTo: 'Bamako',
          EmployeeImage:
            "https://cdn-icons-png.flaticon.com/512/8955/8955262.png",
        });

        if(item.role.id === 3) nbAgent += 1
        if(item.role.id === 4) nbAdmin += 1
         nbTotal += 1
      }
    }); 



    
    


    console.log('result', result)
    setRealData(result.reverse());
    setNbAgent(nbAgent);
    setNbAdmin(nbAdmin)
    setNbTotal(nbTotal)

    
    setToCollect(toCollectAmout);
    
  };

  // Les details du tableau
  const handleRowSelected = (args) => {
    const selectedData = args.data; // Les données de l'élément sélectionné
    console.log("selected data", selectedData)
    setSelectedUser(selectedData)
  };

  const sendUpdateUser = async () => {
      let dataToSend = {id: selectedUser.CustomerID, role: role}
      

    try {
        await updateUser(dataToSend).then(rep => {
          setShowUpdateUser(!showUpdateUser)
          refetch()
          console.log('reponse of update', rep)
        
        })
    } catch (error) {
      console.error('error', error)
    }

  }

  const updateRole = () => (

     
      <div
      id="default-modal"
      tabIndex="-1"
      aria-hidden="true"
      className={` ${
        showUpdateUser ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden flex fixed top-0 right-0 left-0 z-50 justify-center items-center w-full h-[calc(100%-1rem)] max-h-full`}
    >

         <div className="relative p-4 w-full max-w-2xl max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Mettre à jour le role
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="default-modal"
              onClick={() => setShowUpdateUser(!showUpdateUser)}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
                <p> {"Vous allez modifier l'utilisateur suivant: " + selectedUser?.ProjectName } </p>
             <div className="flex mt-6 m-2 p-2 flex-col">
                  <label className='capitalize font-bold underline' for="cars">Chosissez un role:</label>
                   <select className='font-semibold mt-2 mb-6 ' name="role" id="role" onChange={e => setRole(e.target.value)} > 
                    <option value="3">Agent</option>
                    <option value="4">Administrateur</option>
                    <option value="1">Controleur</option>
                   </select>

                   </div>
            <button onClick={() => sendUpdateUser()} className='bg-green-500 w-40 text-white p-2 m-2 rounded-xl shadow-xl shadow-slate-600' > Validé </button>

            </div>

            </div>
        </div>
  )


  useEffect(() => {
    treatData();
  }, [data]);


 console.log('users', data)
  return (
     <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
      
      <Header category="Utilisateurs" title="Gestion des utilisteurs" />
      <Tooltip id="my-tooltip" />
     
        <div className="flex justify-around items-center">
              <button
                  data-modal-target="default-modal"
                  data-modal-toggle="default-modal"
                  onClick={() => setShowForm(!showForm)}
                  class="block text-white text-3xl bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  type="button"
                >
                  <IoIosPersonAdd />
                </button>
              
                <div
              id="default-modal"
              tabIndex="-1"
              aria-hidden="true"
              className={` ${
                showForm ? "" : "hidden"
              } overflow-y-auto overflow-x-hidden flex fixed top-0 right-0 left-0 z-50 justify-center items-center w-full h-[calc(100%-1rem)] max-h-full`}
            >

                <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                  <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                      Créer un utilisateur
                    </h3>
                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-hide="default-modal"
                      onClick={() => setShowForm(!showForm)}
                    >
                      <svg
                        className="w-3 h-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                
                  <Register setShowForm={setShowForm} refetch={refetch} />
                    </div>
                    </div>
                </div>
        </div>

      {updateRole()}
      <div className="flex flex-wrap lg:flex-nowrap justify-center">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
          <div className="flex justify-between items-center">
            <div className="text-center flex items-center justify-center flex-col flex-wrap gap-3">
              <p className="font-bold text-gray-400 ">Utilisateurs</p>
              <p className="text-xl font-semibold">
                {!nbTotal ? <RiLoader3Line /> : nbTotal}{" "}
              </p>
            </div>
          </div>
          <div className="mt-6">
            <Button
              color="white"
              bgColor={currentColor}
              text="Télécharger"
              borderRadius="10px"
              size="md"
              click={() =>  grid.excelExport({ fileName:  'Utilisateurs' +  Math.floor(Math.random()*(999-100+1)+100) + '.xlsx'})}

            />
          </div>
        </div>

        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          {headers.map((item, i) => (
            <div
              key={i}
              className="bg-white flex flex-col items-center justify-center dark:text-gray-200 dark:bg-secondary-dark-bg md:w-46 p-4 pt-9 rounded-2xl"
            >
              <button
                type="button"
                style={{ color: item.iconColor, backgroundColor: item.iconBg }}
                className="text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl"
              >
                {item.icon}
              </button>
              <p className="mt-3 text-center">
                <span className="text-lg font-semibold">{item.amount}</span>
                <span className={`text-sm text-${item.pcColor} ml-2`}>
                  {item.percentage}
                </span>
              </p>
              <p className="text-sm text-gray-400 mt-1"> {item.title} </p>
            </div>
          ))}
        </div>
      </div>
  
      {isLoading  ? (
        <div className="text-[275px] text-blue-950 text-center w-[100%] h-auto flex items-center justify-center ">
          <RiLoader3Line className="animate-spin" />
        </div>
        
      ) : (
        <GridComponent
         id='Grid'
          dataSource={realData}
          allowPaging
          allowSorting
          toolbar={["Delete", "ExcelExport", "Search"]}
          editSettings={{ allowDeleting: true }}
          allowFiltering={true}
          width="auto"
          height={"auto"}
          ref={(g) => (grid = g)}
          excelExportComplete={excelExportComplete}
          allowExcelExport={true}
          toolbarClick={toolbarClick}
          
          
          
          rowSelected={handleRowSelected}
        >
          <ColumnsDirective>
            {usersGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Sort, Filter, DetailRow, ExcelExport, Search]} />
        </GridComponent>
      )}
    </div>
  )
}

export default Users