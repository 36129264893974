import React, { useEffect, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Edit,
  Search,
  Toolbar,
  Inject,
  Filter,
  ExcelExport,
  Sort,
  ToolbarItems
} from "@syncfusion/ej2-react-grids";

import {
  employeesData,
  contextMenuItems,
  employeesGrid,
  auteursData,
} from "../data/dummy";
import { Header, Button } from "../components";
import { IoIosPersonAdd } from "react-icons/io";
import { fieldsPerson as fields } from "../components/Fields";
import avatar3 from "../data/avatar3.png";

import { useStateContext } from "../contexts/ContextProvider";
import Modal from "../components/Modal";
import { authorType } from "../components/Fields";
import {
  useGetAuteurQuery,
  usePostAuteurMutation,
  usePostFilesMutation,
} from "../redux/auteursService";

import { NavLink } from "react-router-dom";
import { GrLocation, GrMoney } from "react-icons/gr";
import { BiLoader, BiLoaderCircle } from "react-icons/bi";
import { usePostNotificationMutation } from "../redux/notification";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RiContactsLine, RiLoader3Line } from "react-icons/ri";
import { BsCurrencyDollar } from "react-icons/bs";
import cfa from '../assets/fcfa.png'

const Auteurs = () => {
  const { currentColor } = useStateContext();
  const [showForm, setShowForm] = useState(false);
  const [postAuteurs] = usePostAuteurMutation();
  const [postFiles] = usePostFilesMutation();
  const { data, isLoading, isFetching, refetch } = useGetAuteurQuery("");
  const [postNotification] = usePostNotificationMutation();
  const [realData, setRealData] = useState();
  // const [postRedevableMorale] = usePostRedevableMoraleMutation()
  const [nbAuteurs, setNbAuteurs] = useState()

  const stateUser = useSelector((state) => state.user);
  // console.log("local auth", localStorage.getItem("auth"));

  const user = stateUser
    ? stateUser
    : localStorage.getItem("auth") && localStorage.getItem("auth") !== "undefined"
      ? JSON.parse(localStorage.getItem("auth"))
      : null;

  let grid;

  const toolbar = ['ExcelExport'];
  const toolbarClick = (args) => {
    if (grid && args.item.id === 'Grid_excelexport') {
      grid.excelExport();
    }
  };

  const excelExportComplete = () => {
    grid.hideSpinner();
  }



  const gridEmployeeProfile = (props) => (
    <NavLink to={`/auteur/${props?.EmployeeID}`}>
      {/* {console.log("auteurs", props.EmployeeID)} */}
      <div className="flex items-center gap-2">
        <img
          className="rounded-full w-10 h-10"
          src={props.EmployeeImage}
          alt="employee"
        />
        <p>{props.Name}</p>
      </div>
    </NavLink>
  );

  const gridEmployeeCountry = (props) => (
    <div className="flex items-center justify-center gap-2">
      <GrLocation />
      <span>{props.Country}</span>
    </div>
  );

  const employeesGrid = [
    {
      headerText: "Nom",
      width: "150",
      template: gridEmployeeProfile,
      textAlign: "Center",
    },
    { field: "Name", headerText: "", width: "0", textAlign: "Center" },
    {
      field: "Title",
      headerText: "Catégorie",
      width: "170",
      textAlign: "Center",
    },
    {
      headerText: "Pays",
      width: "120",
      textAlign: "Center",
      template: gridEmployeeCountry,
    },

    {
      field: "HireDate",
      headerText: "Création",
      width: "135",
      format: "yMd",
      textAlign: "Center",
    },

    {
      field: "ReportsTo",
      headerText: "Region",
      width: "120",
      textAlign: "Center",
    },
    {
      field: "EmployeeID",
      headerText: "ID",
      width: "125",
      textAlign: "Center",
    },
  ];

  const treatData = (filter) => {
    let result = [];
    console.log("data", data);
    data?.data.map((item) => {
      if (!item.attributes.nom) {
        return;
      }

      if (filter) {


        if (filter === 'today') {
          if (new Date(item.attributes.createdAt).getMonth() === new Date().getMonth()) {

            result.push({
              EmployeeID: item.id,
              Name: `${item.attributes.prenom} ${item.attributes.prenom}`,
              Title: item.attributes.category ? item.attributes.category : 'Categorie de l\'auteur',
              HireDate: new Date(item.attributes.date_naissance).toLocaleString("fr"),
              Country: item.attributes.pays,
              ReportsTo: item.attributes.region,
              EmployeeImage:
                " https://static.vecteezy.com/system/resources/thumbnails/008/213/768/small/letter-n-thunderbolt-energy-logo-symbol-icon-design-vector.jpg",
            });
          }

        } else if (filter === '3') {
          if (new Date(item.attributes.createdAt).getMonth() >= new Date().getMonth() - 3 && new Date(item.attributes.createdAt).getMonth() < new Date().getMonth() + 1) {
            result.push({
              EmployeeID: item.id,
              Name: `${item.attributes.prenom} ${item.attributes.prenom}`,
              Title: item.attributes.category ? item.attributes.category : 'Categorie de l\'auteur',
              HireDate: new Date(item.attributes.date_naissance).toLocaleString("fr"),
              Country: item.attributes.pays,
              ReportsTo: item.attributes.region,
              EmployeeImage:
                " https://static.vecteezy.com/system/resources/thumbnails/008/213/768/small/letter-n-thunderbolt-energy-logo-symbol-icon-design-vector.jpg",
            });
          }
        } else if (filter === '6') {

          if (new Date(item.attributes.createdAt).getMonth() >= new Date().getMonth() - 6 && new Date(item.attributes.createdAt).getMonth() < new Date().getMonth() + 1) {
            result.push({
              EmployeeID: item.id,
              Name: `${item.attributes.prenom} ${item.attributes.prenom}`,
              Title: item.attributes.category ? item.attributes.category : 'Categorie de l\'auteur',
              HireDate: new Date(item.attributes.date_naissance).toLocaleString("fr"),
              Country: item.attributes.pays,
              ReportsTo: item.attributes.region,
              EmployeeImage:
                " https://static.vecteezy.com/system/resources/thumbnails/008/213/768/small/letter-n-thunderbolt-energy-logo-symbol-icon-design-vector.jpg",
            });
          }


        } else if (filter === 'year') {

          let date = { m: new Date().getMonth(), y: new Date().getFullYear() }
          let lastYear = { m: new Date().getMonth(), d: new Date().getFullYear() }

          if (new Date(item.attributes.createdAt).getFullYear() >= new Date().getFullYear() - 1 || new Date(item.attributes.createdAt).getFullYear() <= new Date().getFullYear()) {
            result.push({
              EmployeeID: item.id,
              Name: `${item.attributes.prenom} ${item.attributes.prenom}`,
              Title: item.attributes.category ? item.attributes.category : 'Categorie de l\'auteur',
              HireDate: new Date(item.attributes.date_naissance).toLocaleString("fr"),
              Country: item.attributes.pays,
              ReportsTo: item.attributes.region,
              EmployeeImage:
                " https://static.vecteezy.com/system/resources/thumbnails/008/213/768/small/letter-n-thunderbolt-energy-logo-symbol-icon-design-vector.jpg",
            });
          }


        }

      } else {
        result.push({
          EmployeeID: item.id,
          Name: `${item.attributes.prenom} ${item.attributes.prenom}`,
          Title: item.attributes.category ? item.attributes.category : 'Categorie de l\'auteur',
          HireDate: new Date(item.attributes.date_naissance).toLocaleString("fr"),
          Country: item.attributes.pays,
          ReportsTo: item.attributes.region,
          EmployeeImage:
            " https://static.vecteezy.com/system/resources/thumbnails/008/213/768/small/letter-n-thunderbolt-energy-logo-symbol-icon-design-vector.jpg",
        });
      }


      console.log("le resultat", result);
      setNbAuteurs(result.length)
      setRealData(result.reverse());
    });
  };

  const send = async (data) => {
    // data.preventDefault()
    console.log("les data", data);
    const dataToSend = new FormData();

    dataToSend.append("data", JSON.stringify(data));

    dataToSend.append("files.acte", data.acte);
    dataToSend.append("files.photo", data.photo);
    dataToSend.append("files.cin", data.cin);
    dataToSend.append("files.oeuvre", data.oeuvre);
    dataToSend.append("files.attestation", data.attestation);
    dataToSend.append("files.contrat_edition", data.contrat_edition);

    // data.cin = JSON.stringify(data.cin)
    // data.acte = JSON.stringify(data.acte)
    // data.oeuvre = JSON.stringify(data.oeuvre)
    // data.attestation = JSON.stringify(data.attestation)
    // data.contrat_edition = JSON.stringify(data.c)z
    let auteurID;

    console.log("to send", dataToSend);
    try {
      await postAuteurs(dataToSend).then((rep) => {
        console.log("Auteurs reponse", rep);
        auteurID = rep?.data.data.id;
        refetch();
        toast.success("Auteur enregistré");
      });

      console.log("auteur ID", auteurID);

      const notifData = {
        data: {
          user: { connect: [user?.user.id] },
          content: `Auteur ${data.nom} créer avec succè `,
          link: `/auteur/${auteurID}`,
          read: false,
          type: "in-app",
        },
      };
      await postNotification(JSON.stringify(notifData));
    } catch (error) {
      console.error("erreur", error);
    }
  };

  const auteursData = [
    {
      icon: <RiContactsLine />,
      amount: isFetching || isLoading ? (
        <BiLoaderCircle className="animate-spin" />
      ) : nbAuteurs,
      // percentage: "-4%",
      title: "Nouveaux inscrits",
      iconColor: "#03C9D7",
      iconBg: "#E5FAFB",
      pcColor: "red-600",
    },
    {
      icon: <img src={cfa} className="flex object-fill w-9 h-9" />,
      amount: "0",
      // percentage: "+23%",
      /* trunk-ignore(git-diff-check/error) */
      title: "A régler",
      iconColor: "rgb(255, 244, 229)",
      iconBg: "rgb(254, 201, 15)",
      pcColor: "green-600",
    },
  ];

  useEffect(() => {
    treatData();
  }, [data]);

  return (
    <div className="m-2 mt-20 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="Auteurs" />
      <div className="flex justify-around items-center">


        <button
          data-modal-target="default-modal"
          data-modal-toggle="default-modal"
          onClick={() => setShowForm(!showForm)}
          class="block text-white text-3xl bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          type="button"
        >
          <IoIosPersonAdd />
        </button>
        <div className="flex  items-center justify-end lg: mr-36 flex-wrap gap-5 p-2 ">
          <span onClick={() => treatData('today')} style={{ backgroundColor: currentColor }} className={`shadow-sm shadow-black p-2 rounded-xl cursor-pointer  dark:bg-secondary-dark-bg  text-gray-200`}>Mois </span>
          <span onClick={() => treatData('3')} style={{ backgroundColor: currentColor }} className={`shadow-sm shadow-black p-2 rounded-xl cursor-pointer  dark:bg-secondary-dark-bg  text-gray-200`}>3 Mois </span>
          <span onClick={() => treatData('6')} style={{ backgroundColor: currentColor }} className={`shadow-sm shadow-black p-2 rounded-xl cursor-pointer  dark:bg-secondary-dark-bg  text-gray-200`}>6 Mois </span>
          <span onClick={() => treatData('year')} style={{ backgroundColor: currentColor }} className={`shadow-sm shadow-black p-2 rounded-xl cursor-pointer  dark:bg-secondary-dark-bg  text-gray-200`}>Année </span>
        </div>
        <Modal
          toSelect={authorType}
          showForm={showForm}
          send={send}
          setShowForm={setShowForm}
          fields={fields}
        />
      </div>
      <div className="flex flex-wrap lg:flex-nowrap justify-center">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
          <div className="flex justify-between text-center items-center">
            <div>
              <p className="font-bold text-gray-400">NB Auteurs</p>
              <p className="text-xl"> {isFetching || isLoading ? (


                <BiLoaderCircle className="animate-spin" />

              ) : Number(nbAuteurs).toLocaleString()} </p>
            </div>
          </div>
          <div className="mt-6">
            <Button
              color="white"
              bgColor={currentColor}
              text="Télécharger"
              borderRadius="10px"
              size="md"
            />
          </div>
        </div>

        <div className="flex m-3 flex-wrap text-center justify-center gap-1 items-center">
          {auteursData.map((item, i) => (
            <div
              key={i}
              className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56 p-4 pt-9 rounded-2xl"
            >
              <button
                type="button"
                style={{ color: item.iconColor, backgroundColor: item.iconBg }}
                className="text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl"
              >
                {item.icon}
              </button>
              <p className="mt-3">
                <span className="text-lg font-semibold">{item.amount}</span>
                <span className={`text-sm text-${item.pcColor} ml-2`}>
                  {item.percentage}
                </span>
              </p>
              <p className="text-sm text-gray-400 mt-1"> {item.title} </p>
            </div>
          ))}
        </div>
      </div>
      {isFetching || isLoading ? (


        <BiLoaderCircle className="animate-spin" />

      ) : (
        <GridComponent
          id='Grid'
          dataSource={realData}
          allowPaging
          allowSorting
          toolbar={["Delete", "Search", "ExcelExport"]}
          editSettings={{ allowDeleting: true, allowEditing: true }}
          width="auto"
          allowFiltering
          allowExcelExport={true}
          toolbarClick={toolbarClick}
          ref={g => grid = g}
          excelExportComplete={excelExportComplete}
        >
          <ColumnsDirective>
            {employeesGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject services={[Page, Edit, Toolbar, Search, Filter, Sort, ExcelExport]} />
        </GridComponent>
      )}
    </div>
  );
};

export default Auteurs;
