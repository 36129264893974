export const authorType = [
  'author',

  {
    id: 1,
    name: 'Musique',
    rightBase: 10000
  },
  {
    id: 2,
    name: 'Dramatique',
    rightBase: 10000
  },
  {
    id: 3,
    name: 'littéraire',
    rightBase: 10000
  },
  {
    id: 4,
    name: 'cinéma, scénariste',
    rightBase: 10000
  },
  {
    id: 5,
    name: 'Editeur',
    rightBase: 30000
  },

]


export const bureau = [
  {
    id: 1,
    name: 'Bamako'
  },
  {
    id: 2,
    name: 'Kayes'
  },
  {
    id: 3,
    name: 'Koulikoro'
  },
  {
    id: 4,
    name: 'Segou'
  },
  {
    id: 5,
    name: 'Sikasso'
  },
  {
    id: 6,
    name: 'Mopti'
  },
  {
    id: 7,
    name: 'Gao'
  },
  {
    id: 8,
    name: 'Tombouctou'
  },
  {
    id: 9,
    name: 'Kidal'
  },
  {
    id: 10,
    name: 'Taoudeni'
  },
  {
    id: 11,
    name: 'Ménaka'
  },
]

export const redevableType = [
  'redevable',

  {
    id: 1,
    name: 'Dramatique',
    rightBase: 10000,
    lyricsBase: null,
    crPercentBase: null,
    rightPercent: 10,
    crPercent: 1,
    lyricsPercent: null,
    type: 'Seances Occasionnelles',
    nbMin: 20,
    nbMed: 50,
    nbMax: 100

  },
  {
    id: 2,
    name: 'Dramatique 2Actes Minim. Lyrique partie Musicale de 30 mn Maximum',
    rightBase: 8000,
    lyricsBase: 3000,
    crPercentBase: null,
    lPercent: 8,
    crPercent: 0.5,
    lyricsPercent: 3,
    type: 'Seances Occasionnelles',
    nbMin: 20,
    nbMed: 50,
    nbMax: 100

  },
  {
    id: 3,
    name: 'Dramatique 2 Actes Minim. Lyrique partie Musicale de 1H 30 mn Maximum',
    rightBase: 5000,
    lyricsBase: 6000,
    crPercentBase: null,
    rightPercent: 6,
    crPercent: 0.40,
    lyricsPercent: 5,
    type: 'Seances Occasionnelles',
    nbMin: 20,
    nbMed: 50,
    nbMax: 100

  },
  {
    id: 4,
    name: 'Dramatique  Acte seul. Lyrique : partie musicale complétant le spectacle',
    rightBase: 4000,
    lyricsBase: 7000,
    crPercentBase: null,
    rightPercent: 4,
    crPercent: 0.4,
    lyricsPercent: 7,
    type: 'Seances Occasionnelles',
    nbMin: 20,
    nbMed: 50,
    nbMax: 100

  },
  {
    id: 5,
    name: 'Dramatique  1 Acte seul. Lyrique : partie musicale complétant le spectacle',
    rightBase: 3000,
    lyricsBase: 9000,
    crPercentBase: null,
    rightPercent: 2,
    crPercent: 0.2,
    lyricsPercent: 8,
    type: 'Seances Occasionnelles',
    nbMin: 20,
    nbMed: 50,
    nbMax: 100

  },
  {
    id: 6,
    name: 'Concert, Variétés  (Exclusivement)',
    rightBase: 10000,
    lyricsBase: null,
    crPercentBase: null,
    rightPercent: 9,
    crPercent: 1,
    lyricsPercent: null,
    type: 'Seances Occasionnelles',
    nbMin: 20,
    nbMed: 50,
    nbMax: 100

  },
  {
    id: 7,
    name: 'Concert, Variétés + Sketches',
    rightBase: 7000,
    lyricsBase: 3000,
    crPercentBase: null,
    rightPercent: 2,
    crPercent: 1,
    lyricsPercent: 8,
    type: 'Seances Occasionnelles',
    nbMin: 20,
    nbMed: 50,
    nbMax: 100

  },
  {
    id: 8,
    name: 'Film',
    rightBase: 5000,
    lyricsBase: 3000,
    crPercentBase: null,
    rightPercent: 2,
    crPercent: 1,
    lyricsPercent: 4,
    type: 'Seances Occasionnelles',
    nbMin: 20,
    nbMed: 50,
    nbMax: 100

  },
  {
    id: 9,
    name: 'pectacles Vendus 11% sur le P.A',
    rightBase: null,
    lyricsBase: null,
    crPercentBase: null,
    rightPercent: null,
    crPercent: null,
    lyricsPercent: null,
    type: 'Seances Occasionnelles',
    nbMin: 20,
    nbMed: 50,
    nbMax: 100

  },
  {
    id: 10,
    name: 'Tarification Reproduction Mécanique',
    tour: 45,
    other: null,
    rightBase: 1500,
    type: 'Reproduction Mécanique',
    nbMin: 20,
    nbMed: 50,
    nbMax: 100

  },
  //Caafé, Rotisserie, bars, night-club
  {
    id: 11,
    name: 'Café',
    tour: 45,
    other: null,
    rightBaseMin: 14000,
    rightBaseMed: 15000,
    rightBaseMax: 16000,
    type: 'Casinos',
    nbMin: 30,
    nbMed: 100,
    nbMax: 100,
    region: 'Bamako'
  },
  {
    id: 12,
    name: 'Rotisserie',
    tour: 45,
    other: null,
    rightBaseMin: 14000,
    rightBaseMed: 15000,
    rightBaseMax: 16000,
    type: 'Casinos',
    nbMin: 30,
    nbMed: 100,
    nbMax: 100,
    region: 'Bamako'


  },
  {
    id: 13,
    name: 'Bars',
    tour: 45,
    other: null,
    rightBaseMin: 14000,
    rightBaseMed: 15000,
    rightBaseMax: 16000,
    type: 'Casinos',
    nbMin: 30,
    nbMed: 100,
    nbMax: 100,
    region: 'Bamako'


  },
  {
    id: 14,
    name: 'Night-club',
    tour: 45,
    other: null,
    rightBaseMin: 14000,
    rightBaseMed: 15000,
    rightBaseMax: 16000,
    type: 'Casinos',
    nbMin: 30,
    nbMed: 100,
    nbMax: 100,
    region: 'Bamako'
  },

  //Restaurant, pâtesserie, snacks
  {
    id: 15,
    name: 'Restaurant',
    tour: 45,
    other: null,
    rightBase: 1500,
    type: 'Restaurant',
    nbMin: 10,
    nbMed: 50,
    nbMax: 100

  },
  {
    id: 16,
    name: 'Pâtesserie',
    tour: 45,
    other: null,
    rightBase: 1500,
    type: 'Restaurant',
    nbMin: 20,
    nbMed: 50,
    nbMax: 100

  },

  //Hotels et autres établissements d'hebergement
  {
    id: 17,
    name: 'Hotels',
    tour: 45,
    other: null,
    rightBase: 1500,
    type: 'Hotellerie',
    nbMin: 20,
    nbMed: 50,
    nbMax: 100

  },
  {
    id: 18,
    name: 'autres établissements d\'hebergement',
    tour: 45,
    other: null,
    rightBase: 1500,
    type: 'Hotellérie',
    nbMin: 20,
    nbMed: 50,
    nbMax: 100

  },

  // Stations de RadioDiffusion

  {
    id: 19,
    name: 'Radio Commerciale - Bamako',
    tour: 45,
    other: null,
    rightBaseMin: 25000,
    // rightBaseMed: 15000,
    // rightBaseMax: 16000,
    type: 'Radio',
    // nbMin: 30,
    // nbMed: 100,
    // nbMax: 100,
    region: 'Bamako'
  },
  {
    id: 20,
    name: 'Radio associative - Bamako',
    tour: 45,
    other: null,
    rightBaseMin: 20000,
    // rightBaseMed: 15000,
    // rightBaseMax: 16000,
    type: 'Radio',
    // nbMin: 30,
    // nbMed: 100,
    // nbMax: 100,
    region: 'Bamako'
  },

  {
    id: 21,
    name: 'Radio Commerciale - Regions',
    tour: 45,
    other: null,
    rightBaseMin: 15000,
    // rightBaseMed: 15000,
    // rightBaseMax: 16000,
    type: 'Radio',
    // nbMin: 30,
    // nbMed: 100,
    // nbMax: 100,
    region: 'Autres Region'
  },
  {
    id: 22,
    name: 'Radio associative - Region',
    tour: 45,
    other: null,
    rightBaseMin: 10000,
    // rightBaseMed: 15000,
    // rightBaseMax: 16000,
    type: 'Radio',
    // nbMin: 30,
    // nbMed: 100,
    // nbMax: 100,
    region: 'Autres Regions'
  },

  {
    id: 23,
    name: 'Radio Commerciale - Rurale',
    tour: 45,
    other: null,
    rightBaseMin: 10000,
    // rightBaseMed: 15000,
    // rightBaseMax: 16000,
    type: 'Radio',
    // nbMin: 30,
    // nbMed: 100,
    // nbMax: 100,
    region: 'Rurale'
  },
  {
    id: 24,
    name: 'Radio associative - Rurale',
    tour: 45,
    other: null,
    rightBaseMin: 5000,
    // rightBaseMed: 15000,
    // rightBaseMax: 16000,
    type: 'Radio',
    // nbMin: 30,
    // nbMed: 100,
    // nbMax: 100,
    region: 'Rurale'
  },
  {
    id: 25,
    name: 'Chaîne de télévision',
    tour: 45,
    other: null,
    rightBaseMin: 5000000,
    // rightBaseMed: 15000,
    // rightBaseMax: 16000,
    type: 'Radio',
    // nbMin: 30,
    // nbMed: 100,
    // nbMax: 100,
    region: 'Rurale'
  },
  {
    id: 26,
    name: 'Stations de radiodiffusion',
    tour: 45,
    other: null,
    rightBaseMin: 10000000,
    // rightBaseMed: 15000,
    // rightBaseMax: 16000,
    type: 'Radio',
    // nbMin: 30,
    // nbMed: 100,
    // nbMax: 100,
    region: 'Rurale'
  },
  {
    id: 24,
    name: 'Radio associative - Rurale',
    tour: 45,
    other: null,
    rightBaseMin: 5000,
    // rightBaseMed: 15000,
    // rightBaseMax: 16000,
    type: 'Radio',
    // nbMin: 30,
    // nbMed: 100,
    // nbMax: 100,
    region: 'Rurale'
  },
  {
    id: 24,
    name: 'Radio associative - Rurale',
    tour: 45,
    other: null,
    rightBaseMin: 5000,
    // rightBaseMed: 15000,
    // rightBaseMax: 16000,
    type: 'Radio',
    // nbMin: 30,
    // nbMed: 100,
    // nbMax: 100,
    region: 'Rurale'
  },
  {
    id: 24,
    name: 'Radio associative - Rurale',
    tour: 45,
    other: null,
    rightBaseMin: 5000,
    // rightBaseMed: 15000,
    // rightBaseMax: 16000,
    type: 'Radio',
    // nbMin: 30,
    // nbMed: 100,
    // nbMax: 100,
    region: 'Rurale'
  },
  {
    id: 24,
    name: 'Radio associative - Rurale',
    tour: 45,
    other: null,
    rightBaseMin: 5000,
    // rightBaseMed: 15000,
    // rightBaseMax: 16000,
    type: 'Radio',
    // nbMin: 30,
    // nbMed: 100,
    // nbMax: 100,
    region: 'Rurale'
  },

]

export const fieldsPerson = [
  {
    fieldId: 'nom',
    name: 'Nom',
    type: 'text',
    placeholder: 'Traoré, Cissé',
    category: 'identity',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
      minLength: {
        value: 3,
        message: 'min 3 charactères',
      },

      // onChange: (e) => onChange(e)
    },
  },
  {
    fieldId: 'prenom',
    name: 'Prenom',
    type: 'text',
    placeholder: 'Aminata, Brahima',
    category: 'identity',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
      minLength: {
        value: 3,
        message: 'min 3 charactères',
      },
    },
  },

  {
    fieldId: 'date_naissance',
    name: 'Date de naissance',
    type: 'date',
    placeholder: '01-01-1970',
    category: 'date',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },

    },
  },
  {
    fieldId: 'pays',
    name: 'Pays',
    type: 'text',
    placeholder: 'Mali',
    category: 'adresse',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
      minLength: {
        value: 4,
        message: 'min 4 charactères',
      },
    },
  },
  {
    fieldId: 'ville',
    name: 'Ville',
    type: 'text',
    placeholder: 'Bamako, Segou',
    category: 'adresse',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
      minLength: {
        value: 3,
        message: 'min 3 charactères',
      },
    },
  },
  {
    fieldId: 'region',
    name: 'Region',
    type: 'text',
    placeholder: 'Koulikoro, Sikasso',
    category: 'adresse',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
      minLength: {
        value: 3,
        message: 'min 3 charactères',
      },
    },
  },
  {
    fieldId: 'adresse',
    name: 'adresse',
    type: 'text',
    placeholder: 'Lafibougou, Faladie',
    category: 'adresse',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
    },
  },
  {
    fieldId: 'email',
    name: 'email',
    type: 'email',
    placeholder: 'johndoe@bumda.ml',
    category: 'adresse',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
    },
  },
  {
    fieldId: 'phone',
    name: 'Téléphone',
    type: 'text',
    placeholder: '+223 88888888',
    category: 'adresse',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
      minLength: {
        value: 3,
        message: 'min 3 charactères',
      },
    },
  },
  {
    fieldId: 'photo',
    name: "Photo",
    type: 'file',

    category: 'file',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
    },
  },
  {
    fieldId: 'cin',
    name: "Piece identité",
    type: 'file',
    placeholder: 'Près du marché, fouroufourou carré',
    category: 'file',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
    },
  },
  {
    fieldId: 'acte',
    name: 'Acte de naissance',
    type: 'file',
    placeholder: 'Près du marché, fouroufourou carré',
    category: 'file',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
    },
  },
  {
    fieldId: 'attestation',
    name: 'Attestation de diffusion',
    type: 'file',
    placeholder: 'Près du marché, fouroufourou carré',
    category: 'file',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
    },
  },
  {
    fieldId: 'oeuvre',
    name: "Copie oeuvre",
    type: 'file',

    category: 'file',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
    },
  },
  {
    fieldId: 'contrat_edition',
    name: "Contrat edition",
    type: 'file',
    placeholder: 'Près du marché, fouroufourou carré',
    category: 'file',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
    },
  },
]

export const fieldsMoral = [
  {
    fieldId: 'amount',
    name: 'Montant',
    type: 'text',
    placeholder: '5000 Fcfa',
    category: 'amount',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
      minLength: {
        value: 3,
        message: 'min 3 charactères',
      },
    },
  },
  {
    fieldId: 'name',
    name: 'Raison social',
    type: 'text',
    placeholder: 'Africable, Byblos',
    category: 'identity',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
      minLength: {
        value: 3,
        message: 'min 3 charactères',
      },
    },
  },
  {
    fieldId: 'nif',
    name: 'Numero identification Fiscale',
    type: 'text',
    placeholder: 'NIF',
    category: 'identity',
    // validation: {
    //   required: {
    //     value: true,
    //     message: 'requis',
    //   },
    //   minLength: {
    //     value: 3,
    //     message: 'min 3 charactères',
    //   },
    // },
  },
  {
    fieldId: 'nina',
    name: 'NINA',
    type: 'text',
    placeholder: 'NINA',
    category: 'identity',
    validation: {
      // required: {
      //   value: true,
      //   message: 'requis',
      // },
      // minLength: {
      //   value: 2,
      //   message: 'min 2 charactères',
      // },
    },
  },
  {
    fieldId: 'rccm',
    name: 'RCCM',
    type: 'text',
    placeholder: 'Registre de commerce',
    category: 'identity',
    validation: {
      // required: {
      //   value: true,
      //   message: 'requis',
      // },
      // minLength: {
      //   value: 3,
      //   message: 'min 3 charactères',
      // },
    },
  },
  {
    fieldId: 'nb',
    name: 'Nombre',
    type: 'text',
    placeholder: '50',
    category: 'identity',
    validation: {
      // required: {
      //   value: true,
      //   message: 'requis',
      // },
      // minLength: {
      //   value: 2,
      //   message: 'min 2 charactères',
      // },
    },
  },
  {
    fieldId: 'date',
    name: 'Date de creation',
    type: 'date',
    placeholder: '01-01-1970',
    category: 'date',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },

    },
  },
  {
    fieldId: 'representative',
    name: 'Représenter par',
    type: 'text',
    placeholder: 'Mamadou',
    category: 'identity',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
      minLength: {
        value: 3,
        message: 'min 3 charactères',
      },
    },
  },
  {
    fieldId: 'phone',
    name: 'Téléphone',
    type: 'text',
    placeholder: '82 22 22 22',
    category: 'identity',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
      minLength: {
        value: 3,
        message: 'min 3 charactères',
      },
    },
  },
  {
    fieldId: 'pays',
    name: 'Pays',
    type: 'text',
    placeholder: 'Mali',
    category: 'adresse',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
      minLength: {
        value: 3,
        message: 'min 3 charactères',
      },
    },
  },
  {
    fieldId: 'region',
    name: 'Region',
    type: 'text',
    placeholder: 'Bamako, Segou',
    category: 'adresse',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
      minLength: {
        value: 3,
        message: 'min 3 charactères',
      },
    },
  },
  {
    fieldId: 'ville',
    name: 'Ville/Village/Quartier',
    type: 'text',
    placeholder: 'Sirakoro, Magnambougou',
    category: 'adresse',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
      minLength: {
        value: 3,
        message: 'min 3 charactères',
      },
    },
  },
  {
    fieldId: 'commune',
    name: 'Commune',
    type: 'text',
    placeholder: 'Lafibougou, Faladie',
    category: 'adresse',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
      minLength: {
        value: 2,
        message: 'min 2 charactères',
      },
    },
  },
  {
    fieldId: 'adresse_details',
    name: 'Zone',
    type: 'text',
    placeholder: 'Près du marché, fouroufourou carré',
    category: 'adresse',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
      minLength: {
        value: 2,
        message: 'min 2 charactères',
      },
    },
  },
  {
    fieldId: 'begin',
    name: 'Date de debut',
    type: 'date',
    placeholder: 'Près du marché, fouroufourou carré',
    category: 'date',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },

    },
  },
  // {
  //   fieldId: 'end',
  //   name: 'Date de fin',
  //   type: 'date',
  //   placeholder: 'Près du marché, fouroufourou carré',
  //   category: 'date',
  //   validation: {
  //     required: {
  //       value: true,
  //       message: 'requis',
  //     },

  //   },
  // },


]

export const fieldsProgOeuvre = [
  {
    fieldId: 'mois',
    name: 'MOIS',
    type: 'text',
    placeholder: 'Mois',
    category: 'identity',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
      minLength: {
        value: 3,
        message: 'min 3 charactères',
      },
    },
  },
  {
    fieldId: 'titre',
    name: 'titre',
    type: 'text',
    placeholder: 'TITRES DES ŒUVRES',
    category: 'identity',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
      minLength: {
        value: 3,
        message: 'min 3 charactères',
      },
    },
  },

  {
    fieldId: 'minutage',
    name: 'minutage',
    type: 'text',
    placeholder: 'Minutage',
    category: 'identity',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
      minLength: {
        value: 3,
        message: 'min 3 charactères',
      },
    },
  },
]

export const fieldsEvent = [
  {
    fieldId: 'date',
    name: 'Date',
    type: 'date',
    placeholder: 'Date',
    category: 'identity',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
      minLength: {
        value: 3,
        message: 'min 3 charactères',
      },
    },
  },
  {
    fieldId: 'date_fin',
    name: 'Date_fin',
    type: 'date',
    placeholder: 'Date de fin',
    category: 'identity',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
      minLength: {
        value: 3,
        message: 'min 3 charactères',
      },
    },
  },
  {
    fieldId: 'title',
    name: 'title',
    label: 'Titre',
    type: 'text',
    placeholder: 'Titre de l\'evenement',
    category: 'identity',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
      minLength: {
        value: 3,
        message: 'min 3 charactères',
      },
    },
  },

  {
    fieldId: 'lieu',
    name: 'Lieu',
    type: 'text',
    placeholder: 'Lieu',
    category: 'identity',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
      minLength: {
        value: 3,
        message: 'min 3 charactères',
      },
    },
  },
  {
    fieldId: 'budget',
    name: 'Budget',
    type: 'text',
    placeholder: 'Budget',
    category: 'identity',
    validation: {
      required: {
        value: true,
        message: 'requis',
      },
      minLength: {
        value: 3,
        message: 'min 3 charactères',
      },
    },
  },
]

//  export const authorFiedls =  [
//   {
//     fieldId: 'nom',
//     name: 'Nom',
//     type: 'textarea',
//     placeholder: 'Nom',
//     category:'identity'
//   },
//   {
//     fieldId: 'prenom',
//     name: 'Prenom',
//     type: 'textarea',
//     placeholder: 'Prenom',
//     category:'identity'
//   },
//   {
//     fieldId: 'date',
//     name: 'Date de naissance',
//     type: 'textarea',
//     placeholder: 'Nom',
//     category:'identity'
//   },
//   {
//     fieldId: 'nom',
//     name: 'Nom',
//     type: 'textarea',
//     placeholder: 'Nom',
//     category:'identity'
//   },
// ]