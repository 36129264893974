import React, { useEffect, useState } from "react";
import { BsCurrencyDollar } from "react-icons/bs";
// import { GoPrimitiveDot } from 'react-icons/go'

import { Stacked, Pie, Button, SparkLine } from "../components";
import {
  earningData,
  SparklineAreaData,
  ecomPieChartData,
} from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import { BiDotsHorizontal, BiLoaderCircle } from "react-icons/bi";
import { useGetRedevanceQuery } from "../redux/redevanceService";
import { useGetRedevableQuery } from "../redux/redevableService";
import { MdOutlineSupervisorAccount } from 'react-icons/md';
import { FiBarChart } from "react-icons/fi";
import { HiOutlineRefresh } from 'react-icons/hi';
import { RiLoader2Fill } from "react-icons/ri";

const Ecommerce = () => {
  const { currentColor } = useStateContext();

  const {
    data: RedevableData,
    isSuccess: isRedevableSuccess,
    isLoading: isRedevableLoading,
    isError: isRedevableError,
    isFetching: isRedevableFetching,
    refetch: redevableRefetch,
  } = useGetRedevableQuery("");

  const [realData, setRealData] = useState();
  const [nbRedeveance, setnNbRedeveance] = useState();
  const [toCollect, setToCollect] = useState();
  const [recovered, setRecovered] = useState()
  const [nbPayed, setNbPayed] = useState()

  const { data, isLoading, isSuccess, isFetch } = useGetRedevanceQuery("");

  const earningData = [
    {
      icon: <MdOutlineSupervisorAccount />,
      amount: nbRedeveance ? nbRedeveance : <RiLoader2Fill />,
      percentage: '-4%',
      title: 'Rédevances',
      iconColor: '#03C9D7',
      iconBg: '#E5FAFB',
      pcColor: 'red-600',
    },
    // {
    //   icon: <BsBoxSeam />,
    //   amount: '4,396',
    //   percentage: '+23%',
    //   title: 'Oeuvres',
    //   iconColor: 'rgb(255, 244, 229)',
    //   iconBg: 'rgb(254, 201, 15)',
    //   pcColor: 'green-600',
    // },
    {
      icon: <FiBarChart />,
      amount: nbPayed ? nbPayed : nbPayed === 0 ? 0 : <RiLoader2Fill />,
      percentage: '+38%',
      title: 'Paiements',
      iconColor: 'rgb(228, 106, 118)',
      iconBg: 'rgb(255, 244, 229)',

      pcColor: 'green-600',
    },
    {
      icon: <HiOutlineRefresh />,
      amount: 0,
      percentage: '-12%',
      title: 'Reversés (a venir)',
      iconColor: 'rgb(0, 194, 146)',
      iconBg: 'rgb(235, 250, 242)',
      pcColor: 'red-600',
    },
  ];



  const treatData = (filter) => {
    let result = [];
    let toCollectAmout = 0;
    let nbPayed = 0
    let recovered = 0
    console.log('le filtre', filter)
    data?.data.map((item) => {
      if (item.attributes.name !== null) {
        let theRedevable = RedevableData?.data.find(
          (red) => red.id == item.attributes.redevable
        );
        console.log("la redevance", item);
        if (filter) {


          if (filter === 'today') {
            if (new Date(item.attributes.date).getMonth() === new Date().getMonth()) {
              result.push({
                CustomerID: item.id,
                CustomerName: item.attributes.type,
                CustomerEmail: "",
                HireDate: new Date(item.attributes.date).toLocaleString("fr"),
                Country: item.attributes.status,
                ProjectName: theRedevable?.attributes.name,
                Weeks: Number(item.attributes.montant).toLocaleString(),
                Status: item.attributes.status ? "Payé" : "En attente",
                StatusBg: "#FEC90F",
                Location: theRedevable?.attributes.region,
                CustomerImage:
                  " https://static.vecteezy.com/system/resources/thumbnails/008/213/768/small/letter-n-thunderbolt-energy-logo-symbol-icon-design-vector.jpg",
              });

              console.log((item.attributes.montant).replace(/[^0-9]/g,""))

              toCollectAmout += Number((item.attributes.montant).replace(/[^0-9]/g,""));

              if (item.attributes.status === true) {
                nbPayed += 1
                recovered += Number((item.attributes.montant)?.replace(/[^0-9]/g,""))
              }

            }
          } else if (filter === '3') {
            if (new Date(item.attributes.date).getMonth() >= new Date().getMonth() - 3 && new Date(item.attributes.date).getMonth() < new Date().getMonth() + 1) {
              result.push({
                CustomerID: item.id,
                CustomerName: item.attributes.type,
                CustomerEmail: "",
                HireDate: new Date(item.attributes.date).toLocaleString("fr"),
                Country: item.attributes.status,
                ProjectName: theRedevable?.attributes.name,
                Weeks: Number(item.attributes.montant).toLocaleString(),
                Status: item.attributes.status ? "Payé" : "En attente",
                StatusBg: "#FEC90F",
                Location: theRedevable?.attributes.region,
                CustomerImage:
                  " https://static.vecteezy.com/system/resources/thumbnails/008/213/768/small/letter-n-thunderbolt-energy-logo-symbol-icon-design-vector.jpg",
              });

              toCollectAmout += Number((item.attributes.montant).replace(/[^0-9]/g,""))
              if (item.attributes.status === true) {
                nbPayed += 1
                recovered += Number((item.attributes.montant).replace(/[^0-9]/g,""))
              }
            }
          } else if (filter === '6') {

            if (new Date(item.attributes.date).getMonth() >= new Date().getMonth() - 6 && new Date(item.attributes.date).getMonth() < new Date().getMonth() + 1) {
              result.push({
                CustomerID: item.id,
                CustomerName: item.attributes.type,
                CustomerEmail: "",
                HireDate: new Date(item.attributes.date).toLocaleString("fr"),
                Country: item.attributes.status,
                ProjectName: theRedevable?.attributes.name,
                Weeks: Number(item.attributes.montant).toLocaleString(),
                Status: item.attributes.status ? "Payé" : "En attente",
                StatusBg: "#FEC90F",
                Location: theRedevable?.attributes.region,
                CustomerImage:
                  " https://static.vecteezy.com/system/resources/thumbnails/008/213/768/small/letter-n-thunderbolt-energy-logo-symbol-icon-design-vector.jpg",
              });

              toCollectAmout += Number((item.attributes.montant).replace(/[^0-9]/g,""));

              if (item.attributes.status === true) {
                nbPayed += 1
                recovered += Number((item.attributes.montant).replace(/[^0-9]/g,""))              }
            }


          } else if (filter === 'year') {

            let date = { m: new Date().getMonth(), y: new Date().getFullYear() }
            let lastYear = { m: new Date().getMonth(), d: new Date().getFullYear() }

            if (new Date(item.attributes.date).getFullYear() >= new Date().getFullYear() - 1 && new Date(item.attributes.date).getFullYear() <= new Date().getFullYear()) {
              result.push({
                CustomerID: item.id,
                CustomerName: item.attributes.type,
                CustomerEmail: "",
                HireDate: new Date(item.attributes.date).toLocaleString("fr"),
                Country: item.attributes.status,
                ProjectName: theRedevable?.attributes.name,
                Weeks: Number(item.attributes.montant).toLocaleString(),
                Status: item.attributes.status ? "Payé" : "En attente",
                StatusBg: "#FEC90F",
                Location: theRedevable?.attributes.region,
                CustomerImage:
                  " https://static.vecteezy.com/system/resources/thumbnails/008/213/768/small/letter-n-thunderbolt-energy-logo-symbol-icon-design-vector.jpg",
              });

              toCollectAmout += Number((item.attributes.montant).replace(/[^0-9]/g,""));
              if (item.attributes.status === true) {
                nbPayed += 1
                recovered += Number((item.attributes.montant).replace(/[^0-9]/g,""))
              }
            }


          }

        } else {
          result.push({
            CustomerID: item.id,
            CustomerName: item.attributes.type,
            CustomerEmail: "",
            HireDate: new Date(item.attributes.date).toLocaleString("fr"),
            Country: item.attributes.status,
            ProjectName: theRedevable?.attributes.name,
            Weeks: Number(item.attributes.montant).toLocaleString(),
            Status: item.attributes.status ? "Payé" : "En attente",
            StatusBg: "#FEC90F",
            Location: theRedevable?.attributes.region,
            CustomerImage:
              " https://static.vecteezy.com/system/resources/thumbnails/008/213/768/small/letter-n-thunderbolt-energy-logo-symbol-icon-design-vector.jpg",
          });
          console.log((item.attributes.montant)?.replace(/[^0-9]/g,""))
          toCollectAmout += Number((item.attributes.montant)?.replace(/[^0-9]/g,""));
          if (item.attributes.status === true) {
            nbPayed += 1
            recovered += Number((item.attributes.montant)?.replace(/[^0-9]/g,""))
          }
        }



      }
    });


    console.log('payed', nbPayed)
    console.log('recovered', recovered)

    setRealData(result);
    setnNbRedeveance(result.length);
    setNbPayed(nbPayed)
    setRecovered(recovered)

    console.log("collecter", toCollectAmout);
    setToCollect(toCollectAmout);
    console.log("resultat", result);
  };



  useEffect(() => {
    treatData();
  }, [data]);

  return (
    <div className="mt-20">
      <div className="flex items-center justify-end lg: mr-36 flex-wrap gap-5 p-2 ">
        <span onClick={() => treatData('today')} style={{ backgroundColor: currentColor }} className={`shadow-sm shadow-black p-2 rounded-xl cursor-pointer  dark:bg-secondary-dark-bg  text-gray-200`}>Mois </span>
        <span onClick={() => treatData('3')} style={{ backgroundColor: currentColor }} className={`shadow-sm shadow-black p-2 rounded-xl cursor-pointer  dark:bg-secondary-dark-bg  text-gray-200`}>3 Mois </span>
        <span onClick={() => treatData('6')} style={{ backgroundColor: currentColor }} className={`shadow-sm shadow-black p-2 rounded-xl cursor-pointer  dark:bg-secondary-dark-bg  text-gray-200`}>6 Mois </span>
        <span onClick={() => treatData('year')} style={{ backgroundColor: currentColor }} className={`shadow-sm shadow-black p-2 rounded-xl cursor-pointer  dark:bg-secondary-dark-bg  text-gray-200`}>Année </span>
      </div>
      <div className="flex flex-wrap lg:flex-nowrap justify-center">

        <div className="bg-white  dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-auto p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">

          <div className="flex justify-between items-center">
            <div>
              <p className="font-bold text-gray-400">Recouvrement</p>
              <p className="text-xl font-semibold">
                {console.log('recovered', recovered)}
                {recovered ? recovered.toLocaleString() : recovered === 0 ? 0 : <BiLoaderCircle />}
                <span className="text-sm text-yellow-600 font-semibold  mb-16 "> Fcfa</span>{" "}
              </p>
            </div>
          </div>
          <div className="mt-6">
            <Button
              color="white"
              bgColor={currentColor}
              text="Télécharger"
              borderRadius="10px"
              size="md"
            />
          </div>
        </div>

        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          {earningData.map((item) => (
            <div
              key={item.title}
              className="bg-white text-center  dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56 p-4 pt-9 rounded-2xl"
            >
              <button
                type="button"
                style={{ color: item.iconColor, backgroundColor: item.iconBg }}
                className="text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl"
              >
                {item.icon}
              </button>
              <p className="mt-3 text-center">
                <span className="text-lg font-semibold">{item.amount}</span>
                {/* <span className={`text-sm text-${item.pcColor} ml-2`}>
                  {item.percentage}
                </span> */}
              </p>
              <p className="text-sm text-gray-400 mt-1"> {item.title} </p>
            </div>
          ))}
        </div>
      </div>

      <div className="flex gap-10 flex-wrap justify-center">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg m-3 rounded-2xl md:w-780">
          <div className="flex justify-between">
            <p className="font-semibold text-xl">Activités</p>
            <div className="flex items-center gap-4">
              <p className="flex item-center text-gray-600 gap-4 hover:drop-shadow-xl">
                <span>
                  {" "}
                  <BiDotsHorizontal />{" "}
                </span>
                <span>Redistribué</span>
              </p>
              <p className="flex item-center text-green-400 gap-4 hover:drop-shadow-xl">
                <span>
                  {" "}
                  <BiDotsHorizontal />{" "}
                </span>
                <span>Encaissé</span>
              </p>
            </div>
          </div>
          <div className="mt-10 flex gap-10 flex-wrap justify-center">
            <div className="border-r-1 border-colo m-4 pr-10">
              <div>
                <p>
                  <span className="text-3xl font-semibold">{toCollect?.toLocaleString('fr')}</span>{" "}
                  <span className="text-sm text-yellow-300  mb-16 left-5">
                    Fcfa
                  </span>
                  {/* <span className="p-1.5 hover:drop-shadow-xl cursor-pointer rounded-full text-white bg-green-400 ml-3 text-xs">
                    23%
                  </span> */}
                </p>
                <p className="text-gray-500 mt-1">A recouvrer</p>
              </div>
              <div className="mt-8 flex-row align-text-top items-center">
                <p>
                  <span className="text-3xl font-semibold">{(toCollect / 13).toLocaleString('fr')}</span>{" "}
                  <span className="text-sm text-yellow-300  mb-16 left-5">
                    Fcfa
                  </span>
                </p>
                <p className="text-gray-500 mt-1">A redistribuer</p>
              </div>

              <div className="mt-5">
                <SparkLine
                  currentColor={currentColor}
                  id="line-sparkline"
                  type="Line"
                  height="80px"
                  width="250px"
                  data={SparklineAreaData}
                  color={currentColor}
                />
              </div>

              <div className="mt-10">
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Télécharger Rapport"
                  borderRadius="10px"
                />
              </div>
            </div>
            <div>
              <Stacked width="320px" height="360px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ecommerce;
