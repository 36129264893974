import React, { useEffect, useState } from "react";
import {
  ScheduleComponent,
  ViewDirective,
  ViewsDirective,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  Inject,
  Resize,
  DragAndDrop,
} from "@syncfusion/ej2-react-schedule";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { scheduleData } from "../data/dummy";
import { Header, Button } from "../components";
import { BiLoaderCircle } from 'react-icons/bi';
import { redevableType, bureau } from "../components/Fields";
import {
  BsPeople,
} from "react-icons/bs";
import Modal from "../components/Modal";
import { IoIosPersonAdd } from "react-icons/io";
import { useStateContext } from "../contexts/ContextProvider";
import { fieldsEvent as fields } from "../components/Fields";
import { useGetEventQuery, usePostEventMutation } from "../redux/eventService";
import { toast } from "react-toastify";



const Calendar = () => {

  const { currentColor } = useStateContext();
  const [nbEvent, setNbEvent] = useState(0)
  const [showForm, setShowForm] = useState(false)
  const [postEvent] = usePostEventMutation()
  const [realData, setRealData] = useState()
  const { data, isLoading, isFetching, isSuccess, isError, refetch } = useGetEventQuery('')

  const calendarData = [
    {
      icon: <BsPeople />,
      amount: nbEvent,
      // percentage: '-4%',
      title: "Envenements",
      iconColor: "#03C9D7",
      iconBg: "#E5FAFB",
      pcColor: "red-600",
    },
    {
      icon: <BsPeople />,
      amount: "4",
      // percentage: "+23%",
      title: "Payé",
      iconColor: "rgb(255, 244, 229)",
      iconBg: "rgb(254, 201, 15)",
      pcColor: "green-600",
    },
    {
      icon: <BsPeople />,
      amount: "4",
      // percentage: "+23%",
      title: "Non Payé",
      iconColor: "rgb(255, 244, 229)",
      iconBg: "rgb(254, 201, 15)",
      pcColor: "green-600",
    },
  ];

  const treatData = () => {

    let result = []
    let nb = 0
    data?.data.map((item, index) => {
      if (item.attributes.date) {
        result.push({
          Id: index,
          Subject: item.attributes.title,
          Location: item.attributes.lieu,
          StartTime: item.attributes.date,
          EndTime: item.attributes.date_fin,
          CategoryColor: "#1aaa55",
        },)
      }
      nb += 1
    })

    setNbEvent(nb)
    setRealData(result)
  }


  const send = async (data) => {
    try {
      await postEvent({ data }).then(rep => {

        console.log('evenement enregistré', rep.data)
        if (rep.data) toast.success('Evenement enregistré avec succès')
        if (rep.data) refetch()

      })

    } catch (error) {
      console.error('erreur', error)
      toast.error('Une erreur c\'est produite, veuillez réessayer')
    }
  }

  useEffect(() => {
    treatData()
  }, [data, refetch])

  console.log('real data', realData)

  return (
    <div className="m-2 md:m-20 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Evenements" title="Gestion des evenements" />


      <div className="flex justify-around items-center">
        {/* <select className="w-56 border-b-slate-800 border-b-2 border-l-2 rounded-l-lg border-l-slate-800">
          <option value="Bamako">Bamako</option>
          <option value="Commune I">Commune I</option>
          <option value="Commune II">Commune II</option>
          <option value="Commune III">Commune III</option>
          <option value="Commune VI">Commune VI</option>
          <option value="Commune V">Commune V</option>
          <option value="Commune VI">Commune VI</option>
        </select> */}

        <button
          data-modal-target="default-modal"
          data-modal-toggle="default-modal"
          onClick={() => setShowForm(!showForm)}
          class="block text-white text-3xl bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          type="button"
        >
          <IoIosPersonAdd />
        </button>

        <Modal
          //   toSelect={redevableType}
          //   toSelect2={bureau}
          showForm={showForm}
          send={send}
          setShowForm={setShowForm}
          fields={fields}
          //   fieldsMoral={fieldsMoral}
          //   category={null}
          title="Créer un evenement"

        />
      </div>
      <div className="flex flex-wrap lg:flex-nowrap justify-center">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
          <div className="flex justify-between items-center">
            <div>
              <p className="font-bold text-gray-400">Evenements</p>
              <p className="text-xl text-center font-semibold">{nbEvent + 4} </p>
            </div>
          </div>
          <div className="mt-6">
            <Button
              color="white"
              bgColor={currentColor}
              text="Télécharger"
              borderRadius="10px"
              size="md"


            />
          </div>
        </div>

        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          {calendarData?.map((item, i) => (
            <div
              key={i}
              className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg md:w-46 p-4 pt-9 rounded-2xl"
            >
              <button
                type="button"
                style={{ color: item.iconColor, backgroundColor: item.iconBg }}
                className="text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl"
              >
                {item.icon}
              </button>
              <p className="mt-3 text-center">
                <span className="text-lg font-semibold">{item.amount}</span>
                {/* <span className={`text-sm text-${item.pcColor} ml-2`}>
                  {item.percentage}
                </span> */}
              </p>
              <p className="text-sm text-gray-400 mt-1"> {item.title} </p>
            </div>
          ))}
        </div>
      </div>


      <ScheduleComponent
        height="650px"
        eventSettings={{ dataSource: realData }}
        selectedDate={new Date()}

      >
        <Inject services={[Day, Week, WorkWeek, Month, Agenda, Resize, DragAndDrop]} />
      </ScheduleComponent>

    </div>
  );
};

export default Calendar;