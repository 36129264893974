import React, { useEffect, useState } from "react";
import { MdError } from "react-icons/md";
import { useForm, FormProvider } from 'react-hook-form';
import Input from "./Input";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment";

const Form = ({
  fields,
  send,
  fieldsMoral,
  showForm,
  setShowForm,
  office,
  category,
  showError,
  setShowError,
  onSubmit,
  setMoreProgs,
  moreProgs,
  progLength,
  setProgLength,
  auteurs,
  redevable,
  initialData = {},
}) => {
  const [isEntreprise, setIsEntreprise] = useState(false);
  const [selected, setSelected] = useState([]);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [selectedAuteur, setSelectedAuteur] = useState();
  const [selectedDate, setSelectedDate] = useState(moment(initialData.date).format('yyyy-MM-DD') || "");
  const [selectedDateBegin, setSelectedDateBegin] = useState(moment(initialData.begin).format('yyyy-MM-DD') || "");
  const [selectedType, setselectedType] = useState(initialData.category || "")
  const [selectedOffice, setselectedOffice] = useState(initialData.office || "")

  const methods = useForm({ defaultValues: redevable || {} });
  const { register, handleSubmit, setValue, reset, formState: { errors } } = methods;

  useEffect(() => {
    setSelected(fieldsMoral ? fieldsMoral : fields);
  }, [fields, fieldsMoral]);

  useEffect(() => {
    reset({ ...redevable, date: selectedDate, begin: selectedDateBegin });
  }, [redevable, selectedDate, reset, selectedDateBegin]);

  const addMoreFields = () => {
    setAdditionalFields(prevFields => [...prevFields, {}]);
    setMoreProgs(prevProgs => [...prevProgs, {}]);
    setProgLength(prevCount => prevCount + 1);
  };

  const newFieldOnChange = (e, index) => {
    const data = [...additionalFields];
    const author = selectedAuteur ? JSON.parse(selectedAuteur) : { attributes: { nom: "non défini", prenom: "" } };
    data[index] = {
      ...data[index],
      auteur: `${author.attributes.nom} ${author.attributes.prenom}`,
      [e.target.id]: e.target.value,
    };
    setAdditionalFields(data);
    setMoreProgs(data);
  };

  const handleFormSubmit = (data, e) => {
    const filteredData = {
      ...Object.fromEntries(
        Object.entries(data)
      ),
      date: selectedDate,
      begin: selectedDateBegin,
    };
    onSubmit(filteredData);
    reset({ ...redevable, date: "" }); // Reset the form after submitting, keeping default values
    setSelectedDate(""); // Reset the date state
    setSelectedDateBegin("")
    e.target.reset();
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  

  const renderAdditionalFields = (index) => (
    <div className="relative w-full bg-[#F5BD10] bg-opacity-50 rounded-lg shadow dark:bg-gray-700">
      {auteurs && (
        <div className="flex flex-col justify-center items-center">
          <span className="text-sm font-semibold text-slate-500">Choisissez l'auteur</span>
          <select
            onChange={(e) => setSelectedAuteur(e.target.value)}
            name="category"
            className="capitalize ml-2 font-semibold border-solid rounded-3xl border-2 border-blue-600"
            id="category"
          >
            <option selected value="Catégorie"></option>
            {auteurs.map((item) => (
              item.attributes.nom && (
                <option
                  className="capitalize"
                  value={JSON.stringify(item)}
                  key={item.id}
                >
                  {item.attributes.nom + " " + item.attributes.prenom}
                </option>
              )
            ))}
          </select>
          {showError && (
            <AnimatePresence mode="wait" initial={false}>
              <motion.p
                className="flex items-center gap-1 px-2 font-semibold text-red-500 bg-red-100 rounded-md"
                {...framer_error}
              >
                <MdError />
                {"Sélectionnez une catégorie"}
              </motion.p>
            </AnimatePresence>
          )}
        </div>
      )}
      {fields.map((field) => (
        <div key={field.fieldId} className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <Input
            label={`${field?.name} ${index + 1}`}
            id={`${field?.fieldId}`}
            name={`${field?.fieldId} ${index + 1}`}
            type={field?.type}
            placeholder={field?.placeholder + `${index + 1}`}
            validation={field?.validation}
            value={redevable ? redevable[field?.fieldId] : null}
            register={register}
            errors={errors}
            handleSetValue={handleSetValue}
          />
        </div>
      ))}
    </div>
  );

  const handleSetValue = (id) => {
    setValue(id, "");
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleFormSubmit)} className="w-full max-w-lg" key="form-1">
          <div className="flex w-full items-center justify-center m-4 p-2 flex-wrap">
            {selected.map((item, index) => (
              item.category === "amount" && (
                <div className="w-full items-center justify-center md:w-1/3 px-3 mb-6 md:mb-0" key={item.fieldId}>
                  <Input
                    label={item?.name}
                    id={item?.fieldId}
                    name={item?.name}
                    type={item?.type}
                    placeholder={item?.placeholder}
                    validation={item?.validation}
                    value={redevable ? redevable[item?.fieldId] : null}
                    register={register}
                    errors={errors}
                    handleSetValue={handleSetValue}
                  />
                </div>
              )
            ))}
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            {selected.map((item) => (
              item.category === "identity" && (
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0" key={item.fieldId}>
                  <Input
                    label={item?.name}
                    id={item?.fieldId}
                    name={item?.name}
                    type={item?.type}
                    placeholder={item?.placeholder}
                    validation={item?.validation}
                    value={redevable ? redevable[item?.fieldId] : null}
                    register={register}
                    errors={errors}
                    handleSetValue={handleSetValue}
                  />
                </div>
              )
            ))}
          </div>
          {Array.from({ length: additionalFields.length }).map((_, index) => (
            <div className="flex flex-wrap -mx-3 mb-6" key={index}>
              {renderAdditionalFields(index)}
            </div>
          ))}
          {auteurs && (
            <button
              type="button"
              onClick={addMoreFields}
              className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
            >
              Ajouter plus
            </button>
          )}
          <div className="flex flex-wrap -mx-3 mb-6">
            {selected.map((item) => (
              item.category === "date" && (
                <div className="w-full px-3" key={item.fieldId}>
                  <Input
                    label={item?.name}
                    id={item?.fieldId}
                    name={item?.name}
                    type="date"
                    placeholder={item?.placeholder}
                    validation={item?.validation}
                    
                    register={register}
                    errors={errors}
                    
                    onChange={handleDateChange}
                    handleSetValue={handleSetValue}
                    
                  />
                </div>
              )
            ))}
          </div>
          <div className="flex flex-wrap -mx-3 mb-2">
            <div className="flex flex-wrap">
              {selected.map((item) => (
                item.category === "adresse" && (
                  <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0" key={item.fieldId}>
                    <Input
                      label={item?.name}
                      id={item?.fieldId}
                      name={item?.name}
                      type={item?.type}
                      placeholder={item?.placeholder}
                      validation={item?.validation}
                      value={redevable ? redevable[item?.fieldId] : null}
                      register={register}
                      errors={errors}
                      handleSetValue={handleSetValue}
                    />
                  </div>
                )
              ))}
            </div>
            {selected && window.location.pathname === "/auteurs" && (
              <p className="text-yellow-500 font-bold text-xl mt-6 flex m-4 items-center justify-center">
                Les fichiers justificatif de l'auteur
              </p>
            )}
          </div>
          <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
            <button
              data-modal-hide="default-modal"
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Envoyer
            </button>
            <button
              data-modal-hide="default-modal"
              type="button"
              className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              onClick={(e) => {setShowForm(!showForm); }}
            >
              Annuler
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default Form;

const framer_error = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: { duration: 0.2 },
};
