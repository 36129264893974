import React, { useEffect, useState, useRef } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Edit,
  Search,
  Toolbar,
  Filter,
  Inject,
  Sort,
  ExcelExport,
  ToolbarItems
} from "@syncfusion/ej2-react-grids";


import { Header, Button } from "../components";
import { IoIosPersonAdd } from "react-icons/io";
import { fieldsPerson as fields } from "../components/Fields";
import { fieldsMoral } from "../components/Fields";

import { useStateContext } from "../contexts/ContextProvider";
import Modal from "../components/Modal";
import {
  BsPeople,
} from "react-icons/bs";

import {
  useGetRedevableQuery,
  usePostRedevableMoraleMutation,
  usePostRedevableMutation,
  useUpdateRedevableMutation,
} from "../redux/redevableService";
import { ToastContainer, toast } from "react-toastify";
import { redevableType, bureau } from "../components/Fields";

import {
  useGetRedevanceQuery,
  usePostRedevanceMutation,
} from "../redux/redevanceService";
import moment from "moment";
import { BiLoaderCircle } from 'react-icons/bi';
import { CiEdit } from "react-icons/ci";
import { Player } from '@lordicon/react';
import { GrLocation } from "react-icons/gr";
import { NavLink } from "react-router-dom";

import edit_icon from "../assets/wired-outline-35-edit.json"
import { motion } from "framer-motion";
import { FaRegTrashAlt } from "react-icons/fa";

const Employees = () => {
  const { currentColor } = useStateContext();
  const [showForm, setShowForm] = useState(false);
  const [postRedevable] = usePostRedevableMutation();
  const [updateUsager] = useUpdateRedevableMutation()
  const [postRedevance] = usePostRedevanceMutation();
  const [realData, setRealData] = useState([]);
  const [nbRedevable, setnbRedevable] = useState();
  const [position, setPosition] = useState()
  const [leRedev, setLeRedev] = useState()
  const [office, setOffice] = useState()
  const [type, setType] = useState()

  const {
    data: RedevableData,
    isSuccess: isRedevableSuccess,
    isLoading: isRedevableLoading,
    isError: isRedevableError,
    isFetching: isRedevableFetching,
    refetch: redevableRefetch,
  } = useGetRedevableQuery("");

  const { data, isLoading, isSuccess, isFetch } = useGetRedevanceQuery("");
  const playerRef = useRef();




  const find = (props) => {
  
    let id = props.EmployeeID

    

    let found = RedevableData.data?.find(item => item.id === id)
    
    setLeRedev(found)
  

  }


  const gridAction = (props) => {




    return (
      <div className="flex flex-wrap gap-2 justify-center items-center" >

        <motion.div
          whileHover={{ scale: 1.25 }}
          whileTap={{ scale: 1.25 }}
          style={{ color: currentColor }}
          onClick={() => { setShowForm(!showForm); find(props) }}
        >
          <Player
            size={26}
            ref={() => playerRef}
            icon={edit_icon}
          />
        </motion.div>

        <motion.div
          whileHover={{ scale: 1.25 }}
          whileTap={{ scale: 1.25 }}


        >
          <FaRegTrashAlt style={{ color: currentColor }} />
        </motion.div>
      </div >
    )



  };


  let grid;
  const toolbar = ['ExcelExport'];
  const toolbarClick = (args) => {
    if (grid && args.item.id === 'Grid_excelexport') {
      const excelExportProperties = {
        fileName: 'usagers' + Math.floor(Math.random() * (999 - 100 + 1) + 100) + '.xlsx'
      };
      grid.showSpinner();
      grid.excelExport(excelExportProperties);
    }
  }
  const excelExportComplete = () => {
    grid.hideSpinner();
  }
  const gridEmployeeCountry = (props) => (
    <div className="flex items-center justify-center gap-2">
      <GrLocation />
      <span>{props.Country}</span>
    </div>
  );

  const gridEmployeeProfile = (props) => (
    <NavLink to={`/details/${props.EmployeeID}`}>
      <div className="flex items-center gap-2">
        <img
          className="rounded-full w-10 h-10"
          src={props.EmployeeImage}
          alt="employee"
        />
        <p>{props.Name}</p>
      </div>
    </NavLink>
  );
  const employeesGrid = [
    {
      headerText: "Nom",
      width: "150",
      template: gridEmployeeProfile,
      textAlign: "Center",
    },
    { field: "Name", headerText: "", width: "0", textAlign: "Center" },
    {
      field: "Title",
      headerText: "Répresentant",
      width: "170",
      textAlign: "Center",
    },
    {
      headerText: "Pays",
      width: "120",
      textAlign: "Center",
      template: gridEmployeeCountry,
    },

    {
      field: "HireDate",
      headerText: "Création",
      width: "135",
      format: "yMd",
      textAlign: "Center",
    },

    {
      field: "ReportsTo",
      headerText: "Region",
      width: "120",
      textAlign: "Center",
    },
    { field: "EmployeeID", headerText: "ID", width: "125", textAlign: "Center" },

    { field: "action", headerText: "Action", width: "125", textAlign: "Center", template: gridAction, },
  ];

  function error(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }
  function success(pos) {
    const crd = pos.coords;

  
    setPosition(`{latitude: ${crd.latitude}, longitude: ${crd.longitude}}`)
  }
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  navigator.geolocation.getCurrentPosition(success, error, options)


  const send = async (data) => {
    const dataToSend = { ...data, category: type, office, position }
    
    // data ? data?.location = position ? position : "" : null
    // data ? data.office = office ? office : "" : null
    // data ? data.type = type ? type : "" : null
    

    try {
      if (leRedev) {
        const toSend = {...data, id: leRedev.id}
        const id = leRedev.id
    
        try {
          await updateUsager({ data: toSend, id }).then(rep => {
            toast.warning('Redevable Updating...')
            if (rep.error) {
              toast.error('Une erreur est survenue veuillez réessayé')
              return
            }
            toast.success('Redevable mise ')
            redevableRefetch();

            return
          })
        } catch (error) {
          console.log('erreur', error)
          return
        }
       return
      }
      await postRedevable({ data: dataToSend }).then(async (rep) => {
        

        const idRedevable = rep.data.data.id.toString();
        const typeRedevable = rep.data.data.attributes.category;
        const rightBaseMin = data.amount ? data.amount : data.amount;

        if (rep.error) {
          toast.error("Une erreur c'est produite veuillez réessayer");
          return;
        }
        redevableRefetch();

        const dateRedevance = new Date('01-01-2024');
        



        const today = moment().startOf('month'); // Commence au début du mois actuel
        const endOfYear = moment().endOf('year'); // Fin de l'année en cours
      
        // Boucle sur chaque mois à partir du début du mois actuel jusqu'à la fin de l'année
        for (let currentDate = today; currentDate.isSameOrBefore(endOfYear, 'month'); currentDate.add(1, 'month')) {
          let redevance = {
            type: typeRedevable,
            montant: rightBaseMin?.toString(),
            redevable: idRedevable,
            status: false,
            date: currentDate.toDate(),
          };
          
          
          try {
            await postRedevance({ data: redevance }).then((rep) => {
              if (rep.error) {
                toast.error("Erreur lors de la création de la rédevance");
              }
            });
          } catch (error) {
            console.error('Erreur lors de la création de la rédevance:', error);
          }
        }
      
      });
      toast.success("Le redevable est bien enregistré!");
    } catch (error) {
      toast.error(
        "Une erreur c'est produite veuillez réessayé",

      );

      
    }
  };


  const treatData = (filter) => {
    let result = [];
    RedevableData?.data.map((item) => {


      if (item.attributes.name !== null) {

        if (filter) {


          if (filter === 'today') {
            if (new Date(item.attributes.date).getMonth() === new Date().getMonth()) {

              result.push({
                EmployeeID: item.id,
                Name: item.attributes.name,
                Title: item.attributes.representative,
                HireDate: new Date(item.attributes.date).toLocaleString("fr"),
                Country: item.attributes.pays,
                ReportsTo: item.attributes.region,
                EmployeeImage:
                  " https://static.vecteezy.com/system/resources/thumbnails/008/213/768/small/letter-n-thunderbolt-energy-logo-symbol-icon-design-vector.jpg",
              });
            }

          } else if (filter === '3') {
            if (new Date(item.attributes.date).getMonth() >= new Date().getMonth() - 3 && new Date(item.attributes.date).getMonth() < new Date().getMonth() + 1) {
              result.push({
                EmployeeID: item.id,
                Name: item.attributes.name,
                Title: item.attributes.representative,
                HireDate: new Date(item.attributes.date).toLocaleString("fr"),
                Country: item.attributes.pays,
                ReportsTo: item.attributes.region,
                EmployeeImage:
                  " https://static.vecteezy.com/system/resources/thumbnails/008/213/768/small/letter-n-thunderbolt-energy-logo-symbol-icon-design-vector.jpg",
              });
            }
          } else if (filter === '6') {

            if (new Date(item.attributes.date).getMonth() >= new Date().getMonth() - 6 && new Date(item.attributes.date).getMonth() < new Date().getMonth() + 1) {
              result.push({
                EmployeeID: item.id,
                Name: item.attributes.name,
                Title: item.attributes.representative,
                HireDate: new Date(item.attributes.date).toLocaleString("fr"),
                Country: item.attributes.pays,
                ReportsTo: item.attributes.region,
                EmployeeImage:
                  " https://static.vecteezy.com/system/resources/thumbnails/008/213/768/small/letter-n-thunderbolt-energy-logo-symbol-icon-design-vector.jpg",
              });
            }


          } else if (filter === 'year') {

            let date = { m: new Date().getMonth(), y: new Date().getFullYear() }
            let lastYear = { m: new Date().getMonth(), d: new Date().getFullYear() }

            if (new Date(item.attributes.date).getFullYear() >= new Date().getFullYear() - 1 || new Date(item.attributes.date).getFullYear() <= new Date().getFullYear()) {
              result.push({
                EmployeeID: item.id,
                Name: item.attributes.name,
                Title: item.attributes.representative,
                HireDate: new Date(item.attributes.date).toLocaleString("fr"),
                Country: item.attributes.pays,
                ReportsTo: item.attributes.region,
                EmployeeImage:
                  " https://static.vecteezy.com/system/resources/thumbnails/008/213/768/small/letter-n-thunderbolt-energy-logo-symbol-icon-design-vector.jpg",
              });
            }


          }

        } else {
          result.push({
            EmployeeID: item.id,
            Name: item.attributes.name,
            Title: item.attributes.representative,
            HireDate: new Date(item.attributes.date).toLocaleString("fr"),
            Country: item.attributes.pays,
            ReportsTo: item.attributes.region,
            EmployeeImage:
              " https://static.vecteezy.com/system/resources/thumbnails/008/213/768/small/letter-n-thunderbolt-energy-logo-symbol-icon-design-vector.jpg",
          });
        }

      }
    });

    setRealData(result.reverse());
    setnbRedevable(result.length);
    
  };
  useEffect(() => {
    treatData();
  }, [RedevableData]);
  playerRef.current?.playFromBeginning()

  const redevablesData = [
    {
      icon: <BsPeople />,
      amount: nbRedevable,
      // percentage: '-4%',
      title: "Forfaitaire",
      iconColor: "#03C9D7",
      iconBg: "#E5FAFB",
      pcColor: "red-600",
    },
    {
      icon: <BsPeople />,
      amount: "4",
      // percentage: "+23%",
      title: "Pourentage",
      iconColor: "rgb(255, 244, 229)",
      iconBg: "rgb(254, 201, 15)",
      pcColor: "green-600",
    },
  ];
  return (
    <div className="m-2 md:m-10 p-2 mt-20 md:p-10 bg-white rounded-3xl">
      <ToastContainer />
      <Header category="Page" title="Usagers" />


      <div className="flex justify-around items-center">
        {/* <select className="w-56 border-b-slate-800 border-b-2 border-l-2 rounded-l-lg border-l-slate-800">
          <option value="Bamako">Bamako</option>
          <option value="Commune I">Commune I</option>
          <option value="Commune II">Commune II</option>
          <option value="Commune III">Commune III</option>
          <option value="Commune VI">Commune VI</option>
          <option value="Commune V">Commune V</option>
          <option value="Commune VI">Commune VI</option>
        </select> */}


        <button
          data-modal-target="default-modal"
          data-modal-toggle="default-modal"
          onClick={() => setShowForm(!showForm)}
          class="block text-white text-3xl bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          type="button"
        >
          <IoIosPersonAdd />
        </button>
        <div className="flex  items-center justify-end lg: mr-36 flex-wrap gap-5 p-2 ">
          <span onClick={() => treatData('today')} style={{ backgroundColor: currentColor }} className={`shadow-sm shadow-black p-2 rounded-xl cursor-pointer  dark:bg-secondary-dark-bg  text-gray-200`}>Mois </span>
          <span onClick={() => treatData('3')} style={{ backgroundColor: currentColor }} className={`shadow-sm shadow-black p-2 rounded-xl cursor-pointer  dark:bg-secondary-dark-bg  text-gray-200`}>3 Mois </span>
          <span onClick={() => treatData('6')} style={{ backgroundColor: currentColor }} className={`shadow-sm shadow-black p-2 rounded-xl cursor-pointer  dark:bg-secondary-dark-bg  text-gray-200`}>6 Mois </span>
          <span onClick={() => treatData('year')} style={{ backgroundColor: currentColor }} className={`shadow-sm shadow-black p-2 rounded-xl cursor-pointer  dark:bg-secondary-dark-bg  text-gray-200`}>Année </span>
        </div>

        <Modal
          toSelect={redevableType}
          toSelect2={bureau}
          showForm={showForm}
          send={send}
          setShowForm={setShowForm}
          fields={fields}
          fieldsMoral={fieldsMoral}
          category={null}
          redevable={leRedev?.attributes}
          setOffice={setOffice}
          setType={setType}


        />
      </div>
      <div className="flex flex-wrap lg:flex-nowrap justify-center">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
          <div className="flex justify-between items-center">
            <div>
              <p className="font-bold text-gray-400">NB Usagers</p>
              <p className="text-xl text-center font-semibold">{nbRedevable + 4} </p>
            </div>
          </div>
          <div className="mt-6">
            <Button
              color="white"
              bgColor={currentColor}
              text="Télécharger"
              borderRadius="10px"
              size="md"
              click={() => grid.excelExport({ fileName: 'usagers' + Math.floor(Math.random() * (999 - 100 + 1) + 100) + '.xlsx' })}

            />
          </div>
        </div>

        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          {redevablesData?.map((item, i) => (
            <div
              key={i}
              className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg md:w-46 p-4 pt-9 rounded-2xl"
            >
              <button
                type="button"
                style={{ color: item.iconColor, backgroundColor: item.iconBg }}
                className="text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl"
              >
                {item.icon}
              </button>
              <p className="mt-3 text-center">
                <span className="text-lg font-semibold">{item.amount}</span>
                {/* <span className={`text-sm text-${item.pcColor} ml-2`}>
                  {item.percentage}
                </span> */}
              </p>
              <p className="text-sm text-gray-400 mt-1"> {item.title} </p>
            </div>
          ))}
        </div>
      </div>
      {isRedevableLoading || isRedevableFetching ? (
        <BiLoaderCircle className="animate-spin" />

      ) : (
        <GridComponent
          id='Grid'
          dataSource={realData}
          allowPaging
          allowSorting
          toolbar={["Search", "ExcelExport"]}
          width="auto"
          allowFiltering
          allowExcelExport={true}
          toolbarClick={toolbarClick}
          ref={g => grid = g}
          excelExportComplete={excelExportComplete}
        >
          <ColumnsDirective>
            {employeesGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject services={[Page, Edit, Toolbar, Sort, Filter, ExcelExport, Search]} />
        </GridComponent>
      )}
    </div>
  );
};

export default Employees;
