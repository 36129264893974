import React from "react";
import { RiLogoutCircleLine } from "react-icons/ri";
import { AiOutlineProfile } from "react-icons/ai";
import { CiSettings } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../redux/auth/authService";
import { Link, useNavigate } from "react-router-dom";
import { useGetNotificationQuery } from "../redux/notification";

const Notification = ({ showNotif }) => {
  console.log("show notif compo", showNotif);
  const stateUser = useSelector(state => state.user)
  const user = stateUser ? stateUser : localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : null

  const {data, isLoading, isFetching, isError} = useGetNotificationQuery(user?.user.id)
  

  const notifs = [
    {
      title: "Bamart Ajouté",
      content: "Le redevable BamaArt viens d'être ajouter par Moulaye Kebe",
      date: new Date(),
      read: false,
      link: "#"
    },
    {
      title: "Bamart Ajouté",
      content: "Le redevable BamaArt viens d'être ajouter par Moulaye Kebe",
      date: new Date(),
      read: false,
      link: "#"
    },
    {
      title: "Bamart Ajouté",
      content: "Le redevable BamaArt viens d'être ajouter par Moulaye Kebe",
      date: new Date(),
      read: false,
      link: "#"
    },
    {
      title: "Bamart Ajouté",
      content: "Le redevable BamaArt viens d'être ajouter par Moulaye Kebe",
      date: new Date(),
      read: false,
      link: "#"
    },
    {
      title: "Bamart Ajouté",
      content: "Le redevable BamaArt viens d'être ajouter par Moulaye Kebe",
      date: new Date(),
      read: false,
      link: "#"
    },
    {
      title: "Bamart Ajouté",
      content: "Le redevable BamaArt viens d'être ajouter par Moulaye Kebe",
      date: new Date(),
      read: false,
      link: "#"
    },
    {
      title: "Bamart Ajouté",
      content: "Le redevable BamaArt viens d'être ajouter par Moulaye Kebe",
      date: new Date(),
      read: false,
      link: "#"
    },
    {
      title: "Bamart Ajouté",
      content: "Le redevable BamaArt viens d'être ajouter par Moulaye Kebe",
      date: new Date(),
      read: false,
      link: "#"
    },
  ];


  console.log('my notif', data)
  return (
    <div>
      <div className="abosolute " data-te-dropdown-ref>
        <ul
          className={`absolute z-[1000] ${
            showNotif ? "" : "hidden"
          } float-left right-4 top-14 m-0 w-1/4   min-w-max list-none  h-96 rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block overflow-auto`}
          aria-labelledby="dropdownMenuButton1d"
          data-te-dropdown-menu-ref
        >
          {notifs.length > 0 &&
            notifs.map((item, index) => (
              <li className="flex items-center shadow-sm  shadow-blue-500 justify-center">

                <span className="bg-yellow-400 rounded-full m-2 w-10 h-10  text-center flex items-center justify-center text-white text-sm">  {index + 1}  </span>
                <Link
                  class="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                  to={item.link}
                  data-te-dropdown-item-ref
                >
                  <h2 className="flex flex-row gap-2 items-center justify-start font-semibold text-xl" > {item.title} </h2>
                  <p className="flex flex-row gap-2 items-center justify-start w-80 text-wrap  ">
                    <AiOutlineProfile /> {item.content}
                  </p>
                </Link>
              </li>
            ))}

          {/* <li>
          <a
            class="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
            href="#"
            data-te-dropdown-item-ref
          >
            Something else here
          </a>
        </li> */}
          <hr class="my-2 h-0 border border-t-0 border-solid border-neutral-700 opacity-25 dark:border-neutral-200" />
        </ul>
      </div>
    </div>
  );
};

export default Notification;
