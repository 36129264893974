import React, { useEffect, useState } from 'react'
import Modal from './Modal'
import { fieldsProgOeuvre } from './Fields'
import { useGetAuteurQuery } from '../redux/auteursService'
import { usePostProgMutation } from '../redux/progService'
import { toast } from 'react-toastify'

const AddProgOeuvre = ({ showForm, setShowForm, setShowDrop, redevance}) => {

    const [auteur, setAuteur] = useState()
    const {data: auteursData, isLoading, isFetching} = useGetAuteurQuery("")
    const [addProg] = usePostProgMutation()
    const [moreProgs, setMoreProgs] = useState([])
    const [progLength, setProgLength] = useState(0)

    // console.log('progLength', progLength)
    // console.log('more progs', moreProgs)
  const length = moreProgs?.length
    const send = async (data) => {    

      const toSend = {...data, redevable: Number(redevance?.redevableID) , redevance: redevance.CustomerID }
      console.log('to send', data)

      try {
        await addProg({data: toSend}).then(rep => console.log('repnses', rep))
        sendRest()
        toast.success('Programmes enregistré')
      } catch (error) {
        console.log('error', error)
        toast.error('Erreur lors de l\'enregistrement du programme')

      }
        
        setShowDrop(true)

    }

    

    const sendRest = async () => {
          
          console.log('length', length)
          if(progLength > 0){ 
             let i = 0
          for (let index = 0; index < progLength; index++) {
          
           console.log('i', i)
           console.log('more i',moreProgs[i])
          const element = {...moreProgs[i], redevable: Number(redevance?.redevableID) , redevance: redevance.CustomerID }
           
        console.log('to send element', element)
        
        
        

         
          try {
           await addProg({data: element}).then(rep => console.log('more progs rep', rep))
           i = i + 1
          } catch (error) {
            console.warn('error', error)
          }

          
          }
        }
      
    }


  return  <Modal
  
        fields={fieldsProgOeuvre}
         showForm={showForm}
          send={send}
          setShowForm={setShowForm}
          auteurs={auteursData?.data}
          moreProgs={moreProgs}
          setMoreProgs={setMoreProgs}
          progLength={progLength}
          setProgLength={setProgLength}
          
        
        />
    
  
}

export default AddProgOeuvre