import React, { useState } from "react";
import Form from "./Form";
import { useFormContext } from "react-hook-form";
import { AnimatePresence, motion } from "framer-motion";
import { MdError } from "react-icons/md";
import { FormProvider, useForm } from "react-hook-form";

const Modal = ({
  showForm,
  toSelect,
  setShowForm,
  fields,
  fieldsMoral,
  send,
  toSelect2,
  auteurs,
  setMoreProgs,
  moreProgs,
  progLength,
  setProgLength,
  title,
  redevable,
  setOffice,
  setType
}) => {
  const [selected, setSelected] = useState(null);
  const [selected2, setSelected2] = useState(null);
  const [showError, setShowError] = useState(false)
  const [selectedAuteur, setSelectedAuteur] = useState(null)
  const [toSend, setToSend] = useState()
  const width = window.innerWidth

  let dataToSend = {}
  const onChange = (e) => {

    
    dataToSend[e?.target.id] = !e?.target?.files ? e.target.value : e.target.files[0];

    if (selected) dataToSend.office = JSON.parse(selected)?.name;
    if (selected2) dataToSend.category = JSON.parse(selected2)?.name;
    if (selectedAuteur) dataToSend.auteur = JSON.parse(selectedAuteur)?.attributes?.nom

    if (selected) dataToSend.minBase = JSON.parse(selected)?.rightBaseMin ? JSON.parse(selected)?.rightBaseMin : JSON.parse(selected)?.rightBase;



    

    setToSend(prevToSend => ({
      ...prevToSend,
      ...dataToSend
    }));
    return dataToSend;

  };

  const methods = useForm({redevable});

  const onSubmit = (data) => {
    
    send(data);
    setShowForm(!showForm);


  };

 

  return (
    <div
      id="default-modal"
      tabIndex="-1"
      aria-hidden="true"
      className={` ${showForm ? "" : "hidden"
        } overflow-y-auto overflow-x-hidden flex fixed top-0 right-0 left-0 z-50 justify-center items-center w-[${width}px] md:inset-0 h-[calc(100%-1rem)] max-h-full`}
    >
    
      <div className="relative p-4 w-full max-w-2xl max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              {title}
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="default-modal"
              onClick={() => setShowForm(!showForm)}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          {auteurs && (
            <div className="flex flex-col  justify-center  items-center ">
              {auteurs && (
                <span className="text-sm font-semibold text-slate-500">
                  {" "}
                  Choisissez l'auteurs{" "}
                </span>
              )}
              <p className="text-yellow-500 ml-10  p-2 font-bold text-xl bg-white w-40">
                {selected &&
                  selected?.rightBase &&
                  Number(JSON.parse(selected)?.rightBase).toLocaleString() +
                  " Fcfa"}
              </p>
              {auteurs && (
                <select
                  onChange={(e) => {
                    setSelectedAuteur(e.target.value);
                    setOffice(e.target.value)
                    
                  }}
                  name="category"
                  className=" capitalize ml-2 font-semibold border-solid rounded-3xl border-2 border-blue-600 "
                  id="category"
                >
                  {redevable ? <option selected value={redevable.category}></option> : <option selected value="Catégorie"></option>}
                  {auteurs.map(
                    (item) =>
                      item.attributes.nom && (
                        <option
                          className="capitalize"
                          value={JSON.stringify(item)}
                        >
                          {item.attributes.nom + " " + item.attributes.prenom}
                        </option>
                      )
                  )}
                </select>
              )}

              {showError && selected &&
                <AnimatePresence mode="wait" initial={false}>
                  <motion.p
                    className="flex items-center gap-1 px-2 font-semibold text-red-500 bg-red-100 rounded-md"
                    {...framer_error}
                  >
                    <MdError />
                    {"Selectionnez une catégorie"}
                  </motion.p>
                </AnimatePresence>
              }
            </div>
          )

          }

          <div className="flex flex-col  justify-center  items-center ">
            {toSelect && toSelect[0] === "redevable" ? (
              <span className="text-sm font-semibold text-slate-500">
                {" "}
                Rédévance{" "}
              </span>
            ) : toSelect && (
              <span className="text-sm font-semibold text-slate-500">
                {" "}
                Frais d'adhesion{" "}
              </span>
            )}
            <p className="text-yellow-500 ml-10  p-2 font-bold text-xl bg-white w-40">
              {selected &&
                selected?.rightBase &&
                Number(JSON.parse(selected)?.rightBase).toLocaleString() +
                " Fcfa"}
            </p>
            {toSelect && (
              <select
                onChange={(e) => {
                  setSelected(JSON.parse(e.target.value).name);
                  setType(JSON.parse(e.target.value).name)
                  
                }}
                name="category"
                className=" capitalize w-full ml-2 font-semibold border-solid rounded-3xl border-2 border-blue-600 "
                id="category"
              >
               {redevable ? <option selected value={redevable.category}>{redevable.category}</option> : <option selected value="Catégorie">Catégorie</option>}

                {toSelect.map(
                  (item) =>
                    item.name && (
                      <option
                        className="capitalize"
                        value={JSON.stringify(item)}
                      >
                        {item.name}
                      </option>
                    )
                )}
              </select>
            )}

            {showError && selected &&
              <AnimatePresence mode="wait" initial={false}>
                <motion.p
                  className="flex items-center gap-1 px-2 font-semibold text-red-500 bg-red-100 rounded-md"
                  {...framer_error}
                >
                  <MdError />
                  {"Selectionnez une catégorie"}
                </motion.p>
              </AnimatePresence>
            }
          </div>
          {toSelect2 && (
            <div className="flex flex-col mt-10 mb-10 justify-center  items-center ">
              <span className="text-sm m-5 font-semibold text-slate-500">
                Choix du bureau
              </span>

              <select
                onChange={(e) => {
                  setSelected2(JSON.parse(e.target.value).name);
                  setOffice(JSON.parse(e.target.value).name)
                  
                }}
                name="category"
                className=" capitalize ml-2 font-semibold border-solid rounded-3xl border-2 border-blue-600 "
                id="category"
              >
                {redevable ? <option selected value={redevable.office}>{redevable.office}</option> : <option selected value="Bureau">Bureau</option>}

                {toSelect2.map(
                  (item) =>
                    item.name && (
                      <option
                        className="capitalize"
                        value={JSON.stringify(item)}
                      >
                        {item.name}
                      </option>
                    )
                )}
              </select>
            </div>
          )}
          <div className="p-4 md:p-5 space-y-4">
            <Form
              send={send}
              fields={fields}
              showForm={showForm}
              setShowForm={setShowForm}
              fieldsMoral={fieldsMoral}
              category={selected}
              office={selected2}
              showError={showError}
              setShowError={setShowError}
              onChange={onChange}
              methods={methods}
              onSubmit={onSubmit}
              setMoreProgs={setMoreProgs}
              moreProgs={moreProgs}
              progLength={progLength}
              setProgLength={setProgLength}
              auteurs={auteurs}
              redevable={redevable}
            />
            {/* <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              Conseil et instruction !!!!
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;


const framer_error = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: { duration: 0.2 },
};