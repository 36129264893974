import React, { useContext, useEffect, useState } from 'react'
import { useGetProgQuery } from '../redux/progService';
import { NavLink, useParams } from 'react-router-dom';
import { RiLoader3Line } from 'react-icons/ri';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Inject,
  Toolbar,
  Sort,
  Filter,
  DetailRow,
  ExcelExport,
  ToolbarItems,
  Search


} from "@syncfusion/ej2-react-grids";


import { Tooltip } from "react-tooltip";
import { Button, Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';




const DetailsProg = () => {
  const { currentColor } = useStateContext();

    const {id} = useParams()
    const {data: progs, isLoading, isFetching, isError, isSuccess} = useGetProgQuery(id? id : '')

let grid;
    
    const toolbarClick = (args) => {
        if (grid && args.item.id === 'Grid_excelexport') {
            grid.showSpinner();
            grid.excelExport();
        }
    }
    const excelExportComplete = () => {
        grid?.hideSpinner();
    }
 const gridFcfa = (props) => (
    <div className="flex items-center justify-center">
      {props.Weeks ? props.Weeks : ""  }
    </div>
  );

  const linkTemplate = (props) => (
    <NavLink to={"/details"}>
      <a href="">{props.ProjectName}</a>
    </NavLink>
  );


  // les Images du tableau
  const customerGridImage = (props) => (
    <div className="image flex gap-4">
      <img
        className="rounded-full w-10 h-10"
        src={props.CustomerImage}
        alt="employee"
      />
      <div>
        <p>{props.Type}</p>
        <p>{props.CustomerEmail}</p>
      </div>
    </div>
  );

  // les status du tableau

  const customerGridStatus = (props) => (
    <div className="flex gap-2 justify-center items-center text-gray-700 capitalize">
      <p
        style={{ background: props.StatusBg }}
        className="rounded-full h-3 w-3"
      />
      <p>{props.Status}</p>
    </div>
  );
     const redevancessGrid = [
    { type: "checkbox", width: "50" },
    {
      field: "ProjectName",
      headerText: "Auteur",
      template: linkTemplate,
      width: "150",
      textAlign: "Center",
    },
    {
      field: "Type",
      headerText: "Redevable",
      width: "200px",
      template: customerGridImage,
      textAlign: "Center",
    },

    // {
    //   field: "Status",
    //   headerText: "Status",
    //   width: "130",
    //   format: "yMd",
    //   textAlign: "Center",
    //   template: customerGridStatus,
    // },
    {
      field: "Weeks",
      headerText: "Minutages",
      width: "100",
      template: gridFcfa,
      textAlign: "Center",
    },
    {
      field: "date",
      headerText: "Date d'échance",
      width: "200",
      format: "yMd",
      textAlign: "Center",
    },

    // {
    //   field: "Location",
    //   headerText: "Bureau",
    //   width: "150",
    //   textAlign: "Center",
    // },

    {
      field: "CustomerID",
      headerText: "ID",
      width: "120",
      textAlign: "Center",
      isPrimaryKey: true,
    },

    // {
    //   field: "Action",
    //   headerText: "Action",
    //   width: "120",
    //   textAlign: "Center",
    //   isPrimaryKey: true,
    //   template: actionButton,
    // },
  ];


    const [realData, setRealData] = useState()
  const treatData = () => {
    let result = [];

    progs?.data.map((item) => {
      
      if (item.attributes.redevaance !== null) {

            result.push({
              CustomerID: item.id,
              Type: item.attributes?.redevable?.data?.attributes?.name,
              CustomerEmail: "",
              date: item.attributes?.mois,
              Country: item.attributes.status,
              ProjectName: item.attributes?.auteur,
              Weeks: item.attributes.minutage,
              Status: item.attributes.status ? "Payé" : "En attente",
              StatusBg: "#FEC90F",
            //   Location: theRedevable?.attributes.region,
              CustomerImage:
                " https://static.vecteezy.com/system/resources/thumbnails/008/213/768/small/letter-n-thunderbolt-energy-logo-symbol-icon-design-vector.jpg",
              Action: "<button> ... </button>",
              
            });

            return;
          
        } 
    });


    
    

    
    setRealData(result.reverse());
  

    
    
    
  };



    useEffect(() => {
    treatData();
  }, [progs]);



  return (
    <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
        <Header category="Programmes" title="Les oeuvres utilisés" />
      <Tooltip id="my-tooltip" />

<div className="flex flex-wrap lg:flex-nowrap justify-center">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
<div className="mt-6">
            <Button
              color="white"
              bgColor={currentColor}
              text="Télécharger"
              borderRadius="10px"
              size="md"
              click={() =>  grid.excelExport({ fileName:  'programmes' +  Math.floor(Math.random()*(999-100+1)+100) + '.xlsx'})}

            />
          </div>

          </div>
          </div>
            {isLoading || isLoading ? (
        <div className="text-[275px] text-blue-950 text-center w-[100%] h-auto flex items-center justify-center ">
          <RiLoader3Line className="animate-spin" />
        </div>
        
      ) : (
        <GridComponent
          id='Grid'
          dataSource={realData}
          allowPaging
          allowSorting
          toolbar={["Delete"]}
          editSettings={{ allowDeleting: true }}
          allowFiltering={true}
          width="auto"
          height={"auto"}
          ref={(g) => (grid = g)}
          excelExportComplete={excelExportComplete}
          allowExcelExport={true}
          toolbarClick={toolbarClick}
        >
          <ColumnsDirective>
            {redevancessGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Sort, Filter, DetailRow]} />
        </GridComponent>
      )}
    </div>
  )
}

export default DetailsProg