import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePostPaymentMutation } from "../redux/paymentService";
import { toast } from "react-toastify";
import { useGetRedevanceQuery, useUpdateRedevanceMutation } from "../redux/redevanceService";



const OmRedirect = () => {
  const [addPayment] = usePostPaymentMutation();
  const params = useParams();
  const navigate = useNavigate();
  const [updateRedevance] = useUpdateRedevanceMutation()
  const [dataToUpdate, setDataToUpdate] = useState()
  const {refetch} = useGetRedevanceQuery()

  console.log(params);

  const sendRedevance = async (data) => {

    try {
        updateRedevance(data).then(rep => console.log('updated Data', rep))
    } catch (error) {
      console.warn(error)
    }
  }

  
  useEffect(() => {
    window.history.pushState({ path: "/" }, "", "/");
  });

  useEffect(() => {
    if (params.id) {
      if (params.id === "validate") {

     
        console.log("validated");
        const data =
          localStorage.getItem("success_pay") &&
          JSON.parse(localStorage.getItem("success_pay"));

          const redevance =
          localStorage.getItem("redevance") &&
          JSON.parse(localStorage.getItem("redevance"));
          setDataToUpdate(redevance?.data)

          console.log('redevance', redevance)

          let dataUpdated = {...redevance?.data, attributes: {...redevance?.data.attributes, status: true}}
          console.log('data before send', dataUpdated)
          setDataToUpdate(dataUpdated)
        console.log("paiments data", dataUpdated);

        try {
          addPayment({ data }).then((rep) => {
            console.log("reponse payment added", rep);
            if(rep.data && redevance?.data) {
            console.log("Avant envoi", dataUpdated);
             
              sendRedevance(dataUpdated)       
              localStorage.removeItem("success_pay");
              localStorage.removeItem("cancel_pay");
              localStorage.removeItem("redevance");
              localStorage.removeItem("redevable");
            toast.success("Paiement enrégistré avec succès");

             navigate("/redevances");
             refetch()

            }
     
         
          });
        } catch (error) {}
      } else if (params.id === "cancel") {
        console.log("canceled");
        const data =
          localStorage.getItem("cancel_pay") &&
          JSON.parse(localStorage.getItem("cancel_pay"));

        try {
          addPayment({ data }).then((rep) => {
            console.log("reponse payment added", rep);
            toast.error("Paiement annulé");

            localStorage.removeItem("success_pay");
            localStorage.removeItem("cancel_pay");
            if (rep.data) navigate("/ecommerce");
          });
        } catch (error) {}
      } else if (params.id === "notif") {
        console.log("notif");
        // const data = localStorage.getItem('success_pay') && JSON.parse(localStorage.getItem('success_pay'))

        // try {
        //     addPayment({data}).then(rep => console.log('reponse payment added', rep))
        // } catch (error) {

        // }
      }
    }
  }, []);
  return <div>OmRedirect</div>;
};

export default OmRedirect;
