import React, { useEffect, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Grid,
  Toolbar,
  Sort,
  Filter,
  ExcelExport,
  DetailRow,
  ToolbarItems,
  Search
} from "@syncfusion/ej2-react-grids";

import { redevancesData, redevancessGrid } from "../data/dummy";
import { AddProgOeuvre, Button, Header, Payment } from "../components";
import { useGetRedevanceQuery } from "../redux/redevanceService";
import {
  BsKanban,
  BsBarChart,
  BsBoxSeam,
  BsCurrencyDollar,
  BsShield,
  BsChatLeft,
  BsPeople,
} from "react-icons/bs";
import { useStateContext } from "../contexts/ContextProvider";
import { useGetRedevableQuery } from "../redux/redevableService";
import { redevableType } from "../components/Fields";
import { Tooltip } from "react-tooltip";
import { NavLink } from "react-router-dom";
import update from "immutability-helper";
import cfa from "../assets/fcfa.png";
import { RiLoader2Line, RiLoader3Line } from "react-icons/ri";

const Redevances = () => {
  const { data, isLoading, isSuccess, isFetch } = useGetRedevanceQuery("");
  const [showDrop, setShowDrop] = useState(false);
  const [realData, setRealData] = useState();
  const [nbRedeveance, setnNbRedeveance] = useState();
  const [toCollect, setToCollect] = useState();
  const { currentColor } = useStateContext();

  const [selectedRedev, setSelectedRedev] = useState();
  const [toPay, setToPay] = useState();
  const [showForm, setShowForm] = useState(false)

  const gridFcfa = (props) => (
    <div className="flex items-center justify-center">
      {props.Weeks + " Fcfa"}
    </div>
  );

  const linkTemplate = (props) => (
    <NavLink to={"/prog/" + props.CustomerID}>
      <a href="">{props.ProjectName}</a>
    </NavLink>
  );

  const {
    data: RedevableData,
    isSuccess: isRedevableSuccess,
    isLoading: isRedevableLoading,
    isError: isRedevableError,
    isFetching: isRedevableFetching,
    refetch: redevableRefetch,
  } = useGetRedevableQuery("");

  
  const headers = [
    {
      icon: <BsPeople />,
      amount: nbRedeveance ? nbRedeveance : nbRedeveance === 0 ? 0 : < RiLoader3Line />,
      // percentage: '-4%',
      title: "NB Forfaitaire",
      iconColor: "#03C9D7",
      iconBg: "#E5FAFB",
      pcColor: "red-600",
    },
    {
      icon: <BsPeople />,
      amount: "4",
      // percentage: "+23%",
      title: "NB Pourentage",
      iconColor: "rgb(255, 244, 229)",
      iconBg: "rgb(254, 201, 15)",
      pcColor: "green-600",
    },
    {
      icon: <img src={cfa} className="flex object-fill w-9 h-9" />,
      amount: toCollect === 0 ? 0 : !toCollect ? (
        <RiLoader3Line />
      ) : (
        toCollect?.toLocaleString("fr") + " Fcfa"
      ),
      // percentage: '-4%',
      title: "Forfaitaire à collecter",
      iconColor: "#03C9D7",
      iconBg: "#E5FAFB",
      pcColor: "red-600",
    },
    {
      icon: <img src={cfa} className="flex object-fill w-9 h-9" />,
      amount: ".....",
      // percentage: "+23%",
      title: "Pourentage  à collecter",
      iconColor: "rgb(255, 244, 229)",
      iconBg: "rgb(254, 201, 15)",
      pcColor: "green-600",
    },
  ];
  let grid;

  const toolbarClick = (args) => {
    if (grid && args.item.id === 'Grid_excelexport') {
      const excelExportProperties = {
        fileName: 'redevances' + Math.floor(Math.random() * (999 - 100 + 1) + 100) + '.xlsx'
      };
      grid.showSpinner();
      grid.excelExport(excelExportProperties);
    }
  }
  const excelExportComplete = () => {
    grid?.hideSpinner();
  }

  // les Images du tableau
  const customerGridImage = (props) => (
    <div className="image flex gap-4">
      <img
        className="rounded-full w-10 h-10"
        src={props.CustomerImage}
        alt="employee"
      />
      <div>
        <p>{props.CustomerName}</p>
        <p>{props.CustomerEmail}</p>
      </div>
    </div>
  );

  const customerGridStatus = (props) => (
    <div className="flex gap-2 justify-center items-center text-gray-700 capitalize">
      <p
        style={{ background: props.StatusBg }}
        className="rounded-full h-3 w-3"
      />
      <p>{props.Status}</p>
    </div>
  );

  // Les entetes du tableau
  const redevancessGrid = [
    { type: "checkbox", width: "50" },
    {
      field: "ProjectName",
      headerText: "Redevable",
      template: linkTemplate,
      width: "150",
      textAlign: "Center",
    },
    {
      headerText: "Type",
      width: "200px",
      template: customerGridImage,
      textAlign: "Center",
    },

    {
      field: "Status",
      headerText: "Status",
      width: "130",
      format: "yMd",
      textAlign: "Center",
      template: customerGridStatus,
    },
    {
      field: "Weeks",
      headerText: "Montant",
      width: "100",
      template: gridFcfa,
      textAlign: "Center",
    },
    {
      field: "date",
      headerText: "Date d'échance",
      width: "200",
      format: "yMd",
      textAlign: "Center",
    },

    {
      field: "Location",
      headerText: "Bureau",
      width: "150",
      textAlign: "Center",
    },

    {
      field: "CustomerID",
      headerText: "ID",
      width: "120",
      textAlign: "Center",
      isPrimaryKey: true,
    },

    // {
    //   field: "Action",
    //   headerText: "Action",
    //   width: "120",
    //   textAlign: "Center",
    //   isPrimaryKey: true,
    //   template: actionButton,
    // },
  ];


  //Traitement des données du tableau
  const treatData = (filter) => {
    let result = [];
    let toCollectAmout = 0;
    let list = [];
    let nb = 0;

    data?.data.forEach((item, index, arr) => {
      if (item.attributes.redevable !== null) {
        let redevableID = item.attributes?.redevable;
        let redevanceID = item.id;
        let theRedevable =
          redevableID &&
          RedevableData?.data.find((red) => red.id === Number(redevableID));

        const isInFilter = (filterType) => {
          const itemDate = new Date(item.attributes.date);
          const currentDate = new Date();
          switch (filterType) {
            case 'today':
              return itemDate.getMonth() === currentDate.getMonth();
            case '3':
              return itemDate.getMonth() >= currentDate.getMonth() - 3 && itemDate.getMonth() < currentDate.getMonth() + 1;
            case '6':
              return itemDate.getMonth() >= currentDate.getMonth() - 6 && itemDate.getMonth() < currentDate.getMonth() + 1;
            case 'year':
              return itemDate.getFullYear() >= currentDate.getFullYear() - 1 || itemDate.getFullYear() <= currentDate.getFullYear();
            default:
              return true;
          }
        };

        if (filter && !isInFilter(filter)) return;

        nb += 1;
        let existingItemIndex = list.findIndex(listItem => listItem.CustomerID === item?.attributes.redevable);
        let isAllPaid = item.attributes.status;

        if (existingItemIndex >= 0) {
          if (!item.attributes.status) toCollectAmout += Number((item.attributes.montant).replace(/[^0-9]/g,""));
          let element = list[existingItemIndex];
          element.Weeks += Number((item.attributes.montant).replace(/[^0-9]/g,""));
          element.Status = item.attributes.status ? "Payé" : "En attente";
          element.StatusBg = item.attributes.status ? "rgb(26 181 9)" : "#FEC90F";
          element.data.push({
            CustomerID: redevanceID,
            CustomerName: item.attributes.type,
            CustomerEmail: "",
            date: new Date(item.attributes.date).toLocaleString("fr"),
            Country: item.attributes.status,
            ProjectName: theRedevable?.attributes.name,
            Weeks: Number(item.attributes.montant).toLocaleString(),
            Status: item.attributes.status ? "Payé" : "En attente",
            StatusBg: "#FEC90F",
            Location: theRedevable?.attributes.region,
            CustomerImage: " https://static.vecteezy.com/system/resources/thumbnails/008/213/768/small/letter-n-thunderbolt-energy-logo-symbol-icon-design-vector.jpg",
            Action: "<button> ... </button>",
            redevableID,
          });
        } else if (theRedevable && theRedevable.attributes?.name) {
          list.push({
            ProjectName: theRedevable?.attributes.name,
            CustomerID: redevableID,
            CustomerName: item.attributes.type,
            date: new Date(item.attributes.date).toLocaleString("fr"),
            Location: theRedevable?.attributes.region,
            Weeks: 0,
            Status: arr.some(i => i.attributes.redevable == redevableID && i.attributes.status === false) ? "En attente" : "Payé",
            StatusBg: isAllPaid ? "rgb(26 181 9)" : "#FEC90F",
            CustomerImage: " https://static.vecteezy.com/system/resources/thumbnails/008/213/768/small/letter-n-thunderbolt-energy-logo-symbol-icon-design-vector.jpg",
            data: [],
          });
        }
      }
    });

    setRealData(list.reverse());
    setnNbRedeveance(nb);
    setToCollect(toCollectAmout);

  };

  // Les details du tableau
  const handleRowSelected = (args) => {
    const selectedData = args.data; // Les données de l'élément sélectionné

    setSelectedRedev(selectedData);
    for (let i = 0; i <= args?.data?.data.length; i++) {
      if (args?.data?.data[i]?.Status === "En attente") {
        setToPay(args?.data?.data[i]);
        return;
      }
    }
  };

  const gridTemplate = (props) => {
    return <div className="custom-grid"></div>;
  };
  const detailDataBound = (e, onClick) => {

    let detail = new Grid({
      dataSource: e.data.data.filter(item => item["redevableID"] === e.data["CustomerID"]),
      columns: [
        { width: "50" },
        { field: "CustomerID", headerText: "ID", width: 110 },
        { field: "ProjectName", headerText: "Nom", width: 140 },
        { field: "date", headerText: "date", width: 150 },
        { field: "Weeks", headerText: "Montant", width: 150 },
        { field: "Location", headerText: "Bureau", width: 150 },
        { field: "Status", headerText: "Status", width: 150 },
      ],
    });

    detail.appendTo(e.detailElement.querySelector(".custom-grid"));
  };

  useEffect(() => {
    treatData();
  }, [data, redevancesData]);

  return (
    <div className="m-2 mt-20 md:m-10 p-2 md:p-10 bg-white rounded-3xl">

      <Header category="Page" title="Les rédevances" />
      <Tooltip id="my-tooltip" />
      {/* <div className="flex justify-center items-center  flex-wrap gap-8">
        <div className="flex justify-around items-center">
          <select className="w-56 border-b-slate-800 border-b-2 border-l-2 rounded-l-lg border-l-slate-800">
            <option value="">Region</option>
            <option value="Bamako">Bamako</option>
            <option value="Commune I">Commune I</option>
            <option value="Commune II">Commune II</option>
            <option value="Commune III">Commune III</option>
            <option value="Commune VI">Commune VI</option>
            <option value="Commune V">Commune V</option>
            <option value="Commune VI">Commune VI</option>
          </select>
        </div>
        <div className="flex justify-around items-center">
          <select className="w-56 border-b-slate-800 border-b-2 border-l-2 rounded-l-lg border-l-slate-800">
            <option value="">Catégorie</option>
            {redevableType.map((item) => (
              <option value={item.name}>{item.name}</option>
            ))}
          </select>
        </div>
      </div> */}

      <div className="flex  items-center justify-end lg: mr-36 flex-wrap gap-5 p-2 ">
        <span onClick={() => treatData('today')} style={{ backgroundColor: currentColor }} className={`shadow-sm shadow-black p-2 rounded-xl cursor-pointer  dark:bg-secondary-dark-bg  text-gray-200`}>Mois </span>
        <span onClick={() => treatData('3')} style={{ backgroundColor: currentColor }} className={`shadow-sm shadow-black p-2 rounded-xl cursor-pointer  dark:bg-secondary-dark-bg  text-gray-200`}>3 Mois </span>
        <span onClick={() => treatData('6')} style={{ backgroundColor: currentColor }} className={`shadow-sm shadow-black p-2 rounded-xl cursor-pointer  dark:bg-secondary-dark-bg  text-gray-200`}>6 Mois </span>
        <span onClick={() => treatData('year')} style={{ backgroundColor: currentColor }} className={`shadow-sm shadow-black p-2 rounded-xl cursor-pointer  dark:bg-secondary-dark-bg  text-gray-200`}>Année </span>
      </div>
      <AddProgOeuvre showForm={showForm} setShowForm={setShowForm} setShowDrop={setShowDrop} redevance={toPay} />
      <Payment showDrop={showDrop} setShowDrop={setShowDrop} selected={toPay} />

      <div className="flex flex-wrap lg:flex-nowrap justify-center">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
          <div className="flex justify-between items-center">
            <div className="text-center flex items-center justify-center flex-col flex-wrap gap-3">
              <p className="font-bold text-gray-400 ">NB Redevances</p>
              <p className="text-xl font-semibold">
                {nbRedeveance ? nbRedeveance : nbRedeveance === 0 ? 0 : <RiLoader3Line />}{" "}
              </p>
            </div>
          </div>
          <div className="mt-6">
            <Button
              color="white"
              bgColor={currentColor}
              text="Télécharger"
              borderRadius="10px"
              size="md"
              click={() => grid.excelExport({ fileName: 'redevances' + Math.floor(Math.random() * (999 - 100 + 1) + 100) + '.xlsx' })}

            />
          </div>
        </div>

        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          {headers.map((item, i) => (
            <div
              key={i}
              className="bg-white flex flex-col items-center justify-center dark:text-gray-200 dark:bg-secondary-dark-bg md:w-46 p-4 pt-9 rounded-2xl"
            >
              <button
                type="button"
                style={{ color: item.iconColor, backgroundColor: item.iconBg }}
                className="text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl"
              >
                {item.icon}
              </button>
              <p className="mt-3 text-center">
                <span className="text-lg font-semibold">{item.amount}</span>
                <span className={`text-sm text-${item.pcColor} ml-2`}>
                  {item.percentage}
                </span>
              </p>
              <p className="text-sm text-gray-400 mt-1"> {item.title} </p>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center items-center my-2 py-4">
        <button
          disabled={!selectedRedev}
          onClick={() => setShowForm(!showForm)}
          data-modal-target="select-modal"
          data-modal-toggle="select-modal"
          class={`${!selectedRedev
            ? "bg-gray-500 hover:bg-gray-800"
            : "bg-blue-700 hover:bg-blue-800"
            } "block text-white  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"`}
          type="button"
        >
          Paiement
        </button>
      </div>
      {isLoading || isRedevableLoading ? (
        <div className="text-[275px] text-blue-950 text-center w-[100%] h-auto flex items-center justify-center ">
          <RiLoader3Line className="animate-spin" />
        </div>

      ) : (
        <GridComponent
          id='Grid'
          dataSource={realData}
          allowPaging
          allowSorting
          toolbar={["Delete", "ExcelExport", "Search"]}
          editSettings={{ allowDeleting: true }}
          allowFiltering={true}
          width="auto"
          height={"auto"}
          ref={(g) => (grid = g)}
          excelExportComplete={excelExportComplete}
          allowExcelExport={true}
          toolbarClick={toolbarClick}
          onClick={(i, index) => {
            // Get the row index value using its primary key
            // console.log("i", grid);
            // console.log("le grid", grid.getSelectedRowIndexes());
            // var index = grid.getRowIndexByPrimaryKey();
            // Select the row using the index value
            grid.selectRow(grid.selectedRowIndex);
            // console.log("le la grid", grid.selectionModule?.data);
            if (grid.selectedRowIndex === -1) {
              // console.log('-1')
              setSelectedRedev(null);



              return;
            }

            if (grid.selectedRowIndex)
              setSelectedRedev(grid.selectionModule.data);
          }}
          detailTemplate={gridTemplate}
          detailDataBound={detailDataBound}
          rowSelected={handleRowSelected}
        >
          <ColumnsDirective>
            {redevancessGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Sort, Filter, DetailRow, ExcelExport, Search]} />
        </GridComponent>
      )}
    </div>
  );
};

export default Redevances;
