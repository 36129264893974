import React from 'react'
import { Header, MemberCard } from '../components'
import { useGetAuteurQuery } from '../redux/auteursService'
import { useParams } from 'react-router-dom'



const MemberID = () => {
  const {id} = useParams()
  const {data: member, isLoading, isFetching, isError} = useGetAuteurQuery(id)

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl '>
     {console.log('member', member)}
    <Header title='Carte de membre' category={'Carte'}  />
    <MemberCard member={member?.data} />
    </div>
    
  )
}



export default MemberID